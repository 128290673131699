import cConfig from '@/services/chat-config.js';

export default {
  namespaced: true,
  state: {
    actionsProviders: {},
    providersIds: {},
  },
  mutations: {
    setActionProviders(state, { action, providerId }) {
      if (!state.actionsProviders[action]) state.actionsProviders[action] = [];
      if (!state.actionsProviders[action].includes(providerId)) state.actionsProviders[action].push(providerId);
    },
    setProviderId(state, { provider, providerId }) {
      state.providersIds[provider] = providerId;
    },
  },
  getters: {
    actionsProviders: (state) => state.actionsProviders,
    actionProviders: (state) => (action) => state.actionsProviders[action] || [],
    providerId: (state) => (provider) => state.providersIds[provider],
  },
  actions: {
    async fetchProvidersIds({ commit }) {
      try {
        const data = await cConfig.getAllProviders();
        data.forEach((provider) => {
          commit('setProviderId', { provider: provider.name, providerId: provider.id });
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProviderActions({ state, commit, dispatch }, provider) {
      try {
        if (!Object.keys(state.providersIds).length) await dispatch('fetchProvidersIds');
        const providerId = state.providersIds[provider];
        const data = await cConfig.getProviderActions(providerId);
        data.forEach((action) => {
          if (action?.providers[0]?.providerAction?.isActive) {
            commit('setActionProviders', { action: action.name, providerId: provider });
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
