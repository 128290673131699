import { render, staticRenderFns } from "./Callback.ActivityInfoBox.vue?vue&type=template&id=f0ba5380&scoped=true&lang=pug"
import script from "./Callback.ActivityInfoBox.vue?vue&type=script&lang=js"
export * from "./Callback.ActivityInfoBox.vue?vue&type=script&lang=js"
import style0 from "./Callback.ActivityInfoBox.vue?vue&type=style&index=0&id=f0ba5380&prod&lang=postcss&scoped=true"
import style1 from "./Callback.ActivityInfoBox.vue?vue&type=style&index=1&id=f0ba5380&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0ba5380",
  null
  
)

export default component.exports