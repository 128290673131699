<template lang="pug">

v-tooltip(v-if="clientStatus" @apply-show="extractMemberInStatus", theme="menu")
  i.material-icons.notranslate.text-sm.select-none(
    :class="clientStatus === 'read' ? 'text-brand-500' : clientStatus === 'failed' ? 'text-red-500' : 'text-zinc-400'"
  ) {{ clientStatus === 'failed' ? 'close' : clientStatus === 'sent' ? 'done' : 'done_all' }}
  template(#popper)
    .flex.p-4.gap-2.bg-white(v-if="loadingMembersIdentity")
      loading-component.w-6.h-6(class="dark:invert")
      span.text-sm.text-gray-400() Carregando...

    .flex.flex-col.gap-4.p-4.items-start.w-72.bg-white(v-else)
      .flex.items-start.gap-1.flex-col.w-full(v-if="readByIdentity?.length")
        span.text-zinc-700.font-plus.font-bold Vista por
        .flex.flex-col.w-full(v-for="item in readByIdentity")
          .flex.justify-between.items-center.w-full
            .flex.py-2.gap-2
              avatar.w-7.h-7.border-brand(
                :src="item.provider == 'opens' ? `${requestLinks.cservice}/avatar/${item?.recipientId}.png` : `${requestLinks.clist}/uploads/${item?.recipientId}.jpg`"
                :key="item.id",
                :avatar-id="`${item.recipientId}`"
              )
              span.text-sm.text-gray-400() {{ item?.username }}
            span.text-xs.text-gray-400() {{ $moment(item?.status?.updatedAt).format('DD/MM/yyyy [-] HH:mm') }}

      .flex.items-start.gap-1.flex-col(v-if="deliveredByIdentity?.length")
        span.text-zinc-700.font-plus.font-bold Entregue para
        .flex.flex-col(v-for="item in deliveredByIdentity")
          .flex.justify-between.items-center.w-full
            .flex.py-2.gap-2
              avatar.w-7.h-7.border-brand(
                :src="item.provider == 'opens' ? `${requestLinks.cservice}/avatar/${item?.recipientId}.png` : `${requestLinks.clist}/uploads/${item?.recipientId}.jpg`"
                :key="item.id",
                :avatar-id="`${item.recipientId}`"
              )
              span.text-sm.text-gray-400() {{ item?.username }}
            span.text-xs.text-gray-400() {{ $moment(item?.status?.updatedAt).format('DD/MM/yyyy [-] HH:mm') }}

      .flex.items-start.gap-1.flex-col(v-if="pendingMembers?.length")
        span.text-zinc-700.font-plus.font-bold Pendente
        .flex.flex-col(v-for="item in pendingMembers")
          .flex.py-2.gap-2
            avatar.w-7.h-7.border-brand(
              :src="item.provider == 'opens' ? `${requestLinks.cservice}/avatar/${item?.recipientId}.png` : `${requestLinks.clist}/uploads/${item?.recipientId}.jpg`"
              :key="item.id",
              :avatar-id="`${item.recipientId}`"
            )
            span.text-sm.text-gray-400() {{ item?.username }}

</template>

<script>
import { getContactById } from '@/services/contact-list';
import { getUserById } from '@/services/cservice';
import { defineComponent } from 'vue';
import avatar from '../Avatars/avatar.vue';
import LoadingComponent from '../LoadingComponent.vue';

export default defineComponent({
  name: 'ChatMessageStatus',
  components: {
    avatar,
    LoadingComponent,
  },
  data() {
    return {
      readByIdentity: [],
      deliveredByIdentity: [],
      loadingMembersIdentity: false,
      pendingMembers: [],
      allMembersIdentity: {},
    };
  },
  props: {
    messageStatus: {
      type: Array,
    },
    members: {
      type: Array,
      default: null,
      required: false,
    },
  },
  computed: {
    clientStatus() {
      return this.messageStatus?.find((item) => item.chatRoomMemberId === this.clientMember?.id)?.status;
    },
    clientMember() {
      return this.roomMembers?.find((item) => item.provider !== 'opens') || {};
    },
    agentMember() {
      return this.roomMembers?.find((item) => item.recipientId === this.user.id) || {};
    },
    roomMembers() {
      return this?.members || this.$store.getters['chat/activeRoom']?.room?.members;
    },
    noContentToShow() {
      return !this.readByIdentity.length && !this.deliveredByIdentity.length;
    },
  },

  methods: {
    resetMembersIdentity() {
      this.readByIdentity = [];
      this.deliveredByIdentity = [];
      this.pendingMembers = [];
      this.allMembersIdentity = {};
    },
    saveMemberIdentityByStatus(status, payload) {
      if (status === 'read') {
        this.readByIdentity.push(payload);
      } else if (status === 'delivered') {
        this.deliveredByIdentity.push(payload);
      } else {
        this.pendingMembers.push(payload);
      }
    },
    async fetchUserData(member) {
      const { provider, recipientId } = member;
      let user;

      if (provider === 'opens') {
        user = this.$store.getters['usersList/userById'](recipientId);
        if (!user) {
          user = await this.$store.dispatch('usersList/fetchUserById', recipientId);
        }
      } else {
        user = this.$store.getters['contactList/contactById'](recipientId);
        if (!user) {
          user = await this.$store.dispatch('contactList/fetchContactById', recipientId);
        }
      }

      return user;
    },
    async extractMemberInStatus() {
      try {
        this.loadingMembersIdentity = true;
        this.resetMembersIdentity();

        const statusList = this.messageStatus.filter((status) => status.chatRoomMemberId !== this.agentMember.id);

        const promises = statusList.map(async (status) => {
          // Check cache first
          if (this.allMembersIdentity[status.chatRoomMemberId]) {
            const cachedMember = this.allMembersIdentity[status.chatRoomMemberId];
            this.saveMemberIdentityByStatus(status.status, { ...cachedMember, status });
            return;
          }

          // Find member in room
          const member = this.roomMembers?.find((member) => member.id === status.chatRoomMemberId);
          if (!member) return;

          try {
            const user = await this.fetchUserData(member);
            const memberData = {
              ...member,
              username: user?.username || user?.name || 'Desconhecido',
              status,
            };

            this.allMembersIdentity[status.chatRoomMemberId] = memberData;
            this.saveMemberIdentityByStatus(status.status, memberData);
          } catch (error) {
            console.error(`Failed to fetch user data for member ${member.recipientId}:`, error);
            const fallbackData = {
              ...member,
              username: 'Desconhecido',
              status,
            };
            this.saveMemberIdentityByStatus(status.status, fallbackData);
          }
        });

        await Promise.all(promises);
      } catch (error) {
        console.error('Error in extractMemberInStatus:', error);
      } finally {
        this.loadingMembersIdentity = false;
      }
    },
  },
});
</script>
