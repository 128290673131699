import chatService from '@/services/chat';

export default {
  methods: {
    /**
     *
     * @param {object} [filters]
     * @param {boolean} [filters.isOpen]
     * @param {boolean} [filters.reversed]
     */
    async fetchUserChatRooms(filters, options) {
      let requestFilters = {};
      if (filters) requestFilters = { ...filters };

      const [rooms, roomsWithUnreadMessageCount] = await Promise.all([
        chatService.getAllChatsOfMember({
          recipientId: this.user.id,
          companyId: this.user.company_id,
          ...requestFilters,
        }),
        chatService.countUnreadMessagesInAllUserRoom({
          userId: this.user.id,
        }),
      ]);

      let roomsData = rooms.map((data) => {
        let roomMessages = [];
        if (data.room.members.find((member) => member.recipientId == this.user.id && !member.exited)?.new)
          this.$store.commit('chat/addNewRoomWithNewStatus', data.chatRoomId);
        if (data.room?.messages) roomMessages = data.room.messages;
        const room = { ...data.room, messages: roomMessages };

        const roomWithUnreadMessageCount = roomsWithUnreadMessageCount.find(
          (roomWithUnreadMessageCount) => roomWithUnreadMessageCount?.chatRoomId === room.id,
        );

        room.pending = roomWithUnreadMessageCount?.unreadMessages || 0;
        if (room.id == this.$store.getters['chat/activeRoom'].id)
          this.$store.commit('chat/setActiveRoom', {
            roomId: room.id,
            room: room,
          });
        return {
          ...room,
        };
      });

      this.$store.commit('chat/setRooms', roomsData);
    },
    async fetchUserEmailRooms(filters, options) {
      let requestFilters = {};
      if (filters) requestFilters = { ...filters };

      const [rooms, roomsWithUnreadMessageCount] = await Promise.all([
        chatService.getAllChatsOfMember({
          recipientId: this.user.id,
          companyId: this.user.company_id,
          ...requestFilters,
        }),
        chatService.countUnreadMessagesInAllUserRoom({
          userId: this.user.id,
        }),
      ]);

      let roomsData = rooms.map((data) => {
        let roomMessages = [];
        if (data.room.members.find((member) => member.recipientId == this.user.id && !member.exited)?.new)
          this.$store.commit('chat/addNewRoomWithNewStatus', data.chatRoomId);
        if (data.room?.messages) roomMessages = data.room.messages;
        const room = { ...data.room, messages: roomMessages };

        const roomWithUnreadMessageCount = roomsWithUnreadMessageCount.find(
          (roomWithUnreadMessageCount) => roomWithUnreadMessageCount?.chatRoomId === room.id,
        );

        room.pending = roomWithUnreadMessageCount?.unreadMessages || 0;

        if (room?.members)
          for (let i = 0; i < room.members.length; i++) {
            if (room.members[i].provider != 'opens')
              this.$store.cache.dispatch('contactList/fetchContactById', room.members[i].recipientId);
          }

        return {
          ...room,
        };
      });

      this.$store.commit('chat/setRooms', roomsData);
    },
  },
};
