import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CBLIST}`;

export const getAllContactCallbacks = async ({ contactNumber, companyId, assignedTo, status, s }) => {
  try {
    const { data } = await $http.get(
      `${service}/callback/find/?contactNumber=${contactNumber}&companyId=${companyId}&assignedTo=${assignedTo}&status=${status}`,
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getQueuesAbandonCallbacks = async (queues) => {
  try {
    const { data } = await $http.get(`${service}/callback/category/abandon/queue/${queues}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @param {Object} params
 * @param {Array} [params.queues]
 * @param {Number} [params.limit]
 * @param {Number} [params.searchAfter]
 * @param {Number} [params.searchBefore]
 * @param {Boolean} [params.notAssigned]
 * @param {String} [params.assignedTo]
 */
export const getCallbacks = async (params) => {
  try {
    const { data } = await $http.get(`${service}/callback/v2`, {
      params,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @param {Object} params
 * @param {Array} params.queues
 * @param {Boolean} [params.notAssigned]
 */
export const countCallbacks = async (params) => {
  try {
    const { data } = await $http.get(`${service}/callback/count`, {
      params,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCallback = async ({ callbackId, payload }) => {
  try {
    const { data } = await $http.put(`${service}/callback/${callbackId}`, payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCallBackById = async (callbackId) => {
  try {
    const { data } = await $http.get(`${service}/callback/${callbackId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserCallbacks = async (status) => {
  try {
    const { data } = await $http.get(`${service}/callback/status/${status}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const asignCallBack = async (user, callbackId) => {
  try {
    const { data } = await $http.post(`${service}/callback/assign`, { user, callbackId });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {object} payload
 * @param {string} payload.type
 * @param {string} payload.status
 * @param {string} payload.category
 * @param {string} payload.assignedTo
 * @param {string} payload.assignedBy
 * @param {string} payload.assignedByName
 * @param {string} payload.contactNumber
 * @param {string} payload.contactEmail
 * @param {string} payload.linkedid
 * @param {string} payload.note
 */
export const createCallback = async (payload) => {
  try {
    const { data } = await $http.post(`${service}/callback`, payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
