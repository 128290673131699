var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('portal-target',{attrs:{"name":"modal-outlet"}}),_c('portal-target',{attrs:{"name":"modal-outlet-2"}}),(_vm.showNotificationsOffAlert)?_c('notification-off-alert',{on:{"close":function($event){_vm.showNotificationsOffAlert = false}}}):_vm._e(),(_vm.loaded)?_c('div',{class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('div',{staticClass:"fixed z-40 w-full pointer-events-none top-24"},[(
          (_vm.pendingMessages.length > 0 ||
            _vm.invites.filter((invite) => _vm.chatProviders.includes(invite.provider)).length > 0) &&
          this.$route.name !== 'Chat'
        )?_c('chat-alert'):_vm._e(),(
          (_vm.pendingMessages.length > 0 ||
            _vm.invites.filter((invite) => _vm.emailProviders.includes(invite.provider)).length > 0) &&
          this.$route.name !== 'Emails'
        )?_c('email-alert'):_vm._e()],1),(this.$store.getters['GET_MODAL'] === 'confirmPause')?_c('confirm-pause-modal'):_vm._e(),(this.$store.getters['GET_MODAL'] === 'linkCase')?_c('link-case'):_vm._e(),_c('notifications'),(_vm.modal)?_c(_vm.modal,{tag:"component"}):_vm._e(),_c('router-view')],1):_c('div',{staticClass:"loading-page"},[_vm._m(0),_c('div',{staticClass:"loading-page-body"},[(!_vm.error)?_c('div',{staticClass:"loading-bar"},[_c('div',{staticClass:"loading-bar-inside"})]):_vm._e(),(!_vm.error)?_c('div',{staticClass:"loading-text"},[_vm._v("Carregando...")]):_c('div',{staticClass:"loading-texts",staticStyle:{"width":"224px"}},[_vm._m(1),_c('div',{staticClass:"loading-error-text",staticStyle:{"font-size":"20px","color":"#495057","width":"max-content"}},[_vm._v(" Oops! Parece que ocorreu um problema ")]),_c('div',{staticClass:"dialer-error-subtext",staticStyle:{"font-size":"16px","width":"339px"}},[_vm._v(" Para solucionar, verifique sua conexão e tente novamente. ")]),_c('a',{staticClass:"history-button",staticStyle:{"margin-top":"33px"},attrs:{"href":"."}},[_vm._v("Tentar novamente")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-page-header"},[_c('div',{staticClass:"loading-img dark:invert"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"confirm-circle",staticStyle:{"margin-bottom":"24px"}},[_c('div',{staticClass:"confirm-inside-circle",staticStyle:{"color":"white","font-size":"18px"}},[_vm._v("!")])])
}]

export { render, staticRenderFns }