<template lang="pug">
.flex.bg-neutral-50.rounded-tl-md.items-start.justify-start.flex-col.mt-8.mx-8
  p.font-titillium.text-zinc-700.font-extrabold.text-3xl Painel de filas
  .flex.flex-col.w-full.gap-4(v-if="userQueues.length")
    .flex.mt-8.justify-between.w-full.border-b-2
      p.text-lg.font-titillium.font-semibold.w-full Filas
      .flex.gap-2
        p.cursor-pointer.border-b-2.pb-1.transition-all(
          class="hover:border-b-brand-300"
          :class="{'border-b-brand font-semibold' : selectedQueue == queue}"
          @click="selectQueue(queue)"
          v-for="queue in userQueues"
        ) {{ queue }}
    .flex.gap-2.w-full.items-start
      .flex.w-full.border.rounded-md.flex-col(v-if="!hideThisSectionForStz")
        .flex.items-center.gap-2.bg-zinc-100.p-3.w-full.justify-start
          .material-icons.notranslate.text-brand group
          p.font-semibold.text-lg.text-gray-700 Usuários na fila
        .flex.gap-2(
          :class="{'hidden' : !queueWorkers?.length}"
        )
          .flex.relative.w-44.p-2
            canvas(ref="queueCircleChart")
            p.absolute.text-2xl {{ queueWorkers?.length }}
          .flex.flex-col.items-start
            .flex.gap-2.items-center
              .h-3.w-3.rounded-sm.bg-green-400
              p Online
            .flex.gap-2.items-center
              .h-3.w-3.rounded-sm.bg-red-500
              p Ocupado
            .flex.gap-2.items-center
              .h-3.w-3.rounded-sm.bg-yellow-500
              p Em pausa
            .flex.gap-2.items-center
              .h-3.w-3.rounded-sm.bg-gray-400
              p Offline
        .flex.flex-col.w-full.p-2.gap-2
          .flex.w-full 
            icon-input(label="Nome ou ramal do usuário", v-model="search", :key="updateInput")
          .flex.flex-col.divide-y.max-h-96.overflow-y-scroll.justify-start.w-full
            user-in-queue.p-2(
              @updateChart="updateInQueueChart"
              :key="`${user?.agent}-${index}-${selectedQueue}`"
              :agent="user"
              :index="index"
              :queue="selectedQueue"
              v-for="(user, index) in queueWorkers.filter((worker) => worker.name.toLowerCase().includes(search.toLowerCase()) || worker.endpoint.includes(search))"
            )
            p.py-12.text-gray-400(v-if="!queueWorkers.length") Nenhum usuário inserido na fila
      .flex.w-full.border.rounded-md.flex-col
        .flex.justify-between.items-center.w-full.p-2.bg-zinc-100
          .flex.items-center.gap-2.w-full.justify-start
            .material-icons.notranslate.text-green-400 phone
            p.font-semibold.text-lg.text-gray-700 Chamadas aguardando
          .flex.bg-red-400.items-center.justify-center.p-2.rounded-md.px-3
            p.text-white.font-semibold {{ onHold?.length || 0 }}
        .flex.flex-col.w-full.px-2
          calls-on-hold(
            v-for="call in onHold"
            :key="call.number"
            :contact-number="call.number"
            :contact-name="call.contactName"
            :timestamp="call.timestamp"
          )
  .flex.mt-8.justify-between.w-full.flex-col.p-12(v-else)
    .circle-chart-size.half-chart
      .csat-no-reviews
        .material-icons.notranslate error_outline
    .csat-legend-text-aligner
      span.csat-reviews-text Você não está vinculado a nenhuma fila no momento
</template>
<script>
import UserInQueue from '@/components/Cards/UserInQueueCard';
import CallsOnHold from '@/components/Cards/CallsOnHoldCard';
import IconInput from '@/components/Inputs/IconInput.vue';
import Chart from '../../../node_modules/chart.js/auto';
import cstateService from '@/services/cstate.js';
import callReport from '@/services/call-report';

export default {
  title: 'Opens - Painel de filas',
  components: {
    UserInQueue,
    CallsOnHold,
    IconInput,
  },
  data() {
    return {
      selectedQueue: '',
      search: '',
      updateInput: 0,
      hideThisSectionForStz: false,
      graph: null,
      onHold: [],
    };
  },
  watch: {
    async selectedQueue(n, previousQueueSelection) {
      const currentQueue = n;
      if (!currentQueue) return;
      this.onHold = [];
      callReport
        .getQueuedCalls({ queues: [currentQueue] })
        .then((data) => {
          if (currentQueue !== this.selectedQueue) return;
          this.onHold = data.pop().onHold;
        })
        .catch(console.error);
      if (previousQueueSelection) {
        this.websocket.emit(`leave-room`, `${this.user.company_id}:${previousQueueSelection}`);
        this.websocket.on(`queued-calls:${previousQueueSelection}`, this.updateOnHoldList);
      }
      this.buildChart();
      this.websocket.emit(`join-room`, `${this.user.company_id}:${this.selectedQueue}`);
      this.websocket.on(`queued-calls:${this.selectedQueue}`, this.updateOnHoldList);
    },
  },
  computed: {
    websocket() {
      return this.$store.getters['cstate/GET_SOCKET'];
    },
    queueSummary() {
      return this.$store.getters['cstate/GET_QUEUE_SUMMARY'];
    },
    queuePayload() {
      return this.queueSummary[this.selectedQueue];
    },
    queueWorkers() {
      return (
        this.queuePayload?.members
          ?.filter((member) => member.agent != 'null')
          .reduce((unique, member) => {
            if (!unique.some((item) => item?.agent === member?.agent)) unique.push(member);
            return unique;
          }, []) || []
      );
    },
    userQueues() {
      return Object.keys(this.$store.getters['user/GET_USER']?.queues || []);
    },
    isDarkTheme() {
      return document.querySelector('html').className.includes('dark');
    },
  },
  methods: {
    updateOnHoldList(payload) {
      this.onHold = payload.onHold;
    },
    async selectQueue(queue) {
      this.search = '';
      this.updateInput++;
      this.selectedQueue = queue;
      this.$nextTick(() => {
        this.buildChart();
      });
    },
    async setQueueSummary() {
      const queueSummary = await cstateService.getQueueSummary();
      this.$store.commit('cstate/SET_QUEUE_SUMMARY', queueSummary.queues);
    },
    updateInQueueChart({ add, remove }) {
      if (add !== null) this.graph.data.datasets[0].data[add]++;
      const r = this.graph.data.datasets[0].data[remove];
      this.graph.data.datasets[0].data[remove] = Math.max(0, r - 1);
      this.graph.update();
    },
    buildChart() {
      if (this.graph) this.graph.destroy();
      let queueCircleChart = this.$refs.queueCircleChart;
      this.graph = new Chart(queueCircleChart, {
        type: 'doughnut',
        data: {
          labels: ['Online', 'Ocupado', 'Em pausa', 'Offline'],
          datasets: [
            {
              data: [0, 0, 0, 0],
              backgroundColor: [
                this.isDarkTheme ? '#8B3C63' : '#58DCA5',
                this.isDarkTheme ? '#2c9ca3' : '#FF4B2E',
                this.isDarkTheme ? '#1F6598' : '#F8A354',
                '#868E96',
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    },
  },
  async created() {
    if (this.user.company_id == 'cc57952e-a163-4b19-84a5-05f06c2578a1') this.hideThisSectionForStz = true;
    await this.setQueueSummary();
    if (this.userQueues) this.selectQueue(this.userQueues[0]);
  },
  beforeDestroy() {
    if (this.selectedQueue)
      return this.$store.getters['cstate/GET_SOCKET'].emit(
        `leave-room`,
        `${this.user.company_id}-${this.selectedQueue}`,
      );
  },
};
</script>
