<template lang="pug">
.text-xs.text-slate-500.font-plus {{ calldate }}
</template>

<script>
export default {
  data() {
    return {
      calldate: '',
      timer: null,
    };
  },
  props: ['transferCall', 'createdAt'],
  created() {
    let SECONDS = 0;
    this.timer = setInterval(() => {
      SECONDS++;
      if (this.createdAt) {
        const elapsedMs = Date.now() - new Date(this.createdAt).getTime();
        const elapsedSeconds = Math.floor(elapsedMs / 1000);
        this.calldate = new Date(elapsedSeconds * 1000).toISOString().substr(11, 8);
        return;
      }
      this.calldate = new Date(SECONDS * 1000).toISOString().substr(11, 8);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
