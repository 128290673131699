<template lang="pug">
.flex.flex-col.gap-4
  .border-b.border-slate-800.w-40.overflow-auto.flex.items-center.pb-2
    .font-plus.text-white.text-lg {{ input }}
  .flex.flex-col.gap-2
    .flex.gap-2
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(1)")
        .text-white.text-lg.font-plus 1
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(2)")
        .text-white.text-lg.font-plus 2
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(3)")
        .text-white.text-lg.font-plus 3

    .flex.gap-2
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(4)")
        .text-white.text-lg.font-plus 4
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(5)")
        .text-white.text-lg.font-plus 5
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(6)")
        .text-white.text-lg.font-plus 6

    .flex.gap-2
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(7)")
        .text-white.text-lg.font-plus 7
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(8)")
        .text-white.text-lg.font-plus 8
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(9)")
        .text-white.text-lg.font-plus 9

    .flex.gap-2
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked('*')")
        .text-white.text-lg.font-plus *
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked(0)")
        .text-white.text-lg.font-plus 0
      .h-14.w-14.rounded-full.bg-slate-800.flex.items-center.justify-center.cursor-pointer(class='hover:bg-slate-700' @click="clicked('#')")
        .text-white.text-lg.font-plus #

</template>

<script>
import SipMixin from '@/mixins/invite-calls.js';

export default {
  mixins: [SipMixin],
  data() {
    return {
      input: '',
    };
  },
  methods: {
    clicked(number) {
      this.input += `${number}`;
      this.sendDTMF(number);
    },
    sendDTMF(itemStr) {
      if (!this.session || !this.session.session) return;
      var options = {
        duration: 100,
        interToneGap: 70,
      };
      this.session.session.sessionDescriptionHandler.sendDtmf(itemStr, options);
    },
  },
};
</script>
