import { attendTransfer } from '../services/sip';

export default {
  computed: {
    webphone() {
      return this.$store.getters['sip/agent'];
    },
    session() {
      return this.$store.getters['sip/session'];
    },
  },
  methods: {
    createWebphoneAudioStream() {
      try {
        const session = this.session.session;
        const domElement = this.$refs.remoteMedia;
        const pc = session.sessionDescriptionHandler.peerConnection;
        const remoteStream = new MediaStream();
        pc.getReceivers().forEach(function (receiver) {
          const track = receiver.track;
          if (track) {
            remoteStream.addTrack(track);
          }
        });
        domElement.srcObject = remoteStream;
        domElement.play();
      } catch (error) {}
    },
    makeCall(num) {
      const input = `${num}`;
      if (this.webphone) this.$store.dispatch('sip/makeCall', { callNum: input.replace(/\W/g, '') });
      else this.callNumber(input.replace(/\W/g, ''));
    },
    async callNumber(number) {
      let numero;
      let tech_channel = false;
      if (number.startsWith('SIP')) {
        numero = number.split('SIP/')[1];
        tech_channel = 'SIP';
      } else if (number.startsWith('PJSIP')) {
        numero = number.split('PJSIP/')[1];
        tech_channel = 'PJSIP';
      } else {
        numero = number;
      }

      let from;
      from = this.user.endpoint;

      let url = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/call?from=${from}&to=${numero}`;
      if (tech_channel) {
        url += `&tech_channel=${tech_channel}`;
      }
      const res = await this.$http.get(url);
      this.$store.commit('cstate/SET_CHANNEL', res.data.channel);
    },
    hold() {
      if (this.session) {
        this.session.session.invite({
          sessionDescriptionHandlerOptions: { hold: true },
        });
      } else this.holdFunction();
    },
    unhold() {
      if (this.session) {
        this.session.session.invite({
          sessionDescriptionHandlerOptions: { hold: false },
        });
      } else this.holdFunction();
    },
    muteSession(muted) {
      if (!this.session) return;
      if (!this.session.session) return;
      const session = this.session.session;
      const peerConnection = session.sessionDescriptionHandler.peerConnection;
      peerConnection.getSenders().forEach((stream) => {
        stream.track.enabled = !muted;
      });
    },
    attendTransfer(num) {
      if (true) this._transferCall(num);
      else {
        this.hold();
        attendTransfer({
          outgoingSession: this.session.session,
          transferNum: num,
        });
      }
    },
  },
};
