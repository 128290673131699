<template lang="pug">
.px-2.py-1.pr-1.rounded-xl.bg-gradient-to-br.flex.gap-1.relative.justify-between.bg-gray-100.cursor-pointer.border.h-11(
  :key="user.pause || Math.random()"
  class="min-w-[16rem] hover:bg-gray-200"
  @click="confirmPause"
)
  .flex-col.items-start.justify-start
    .flex.gap-2.justify-start
      .h-3.w-3.rounded-full(
        class="dark:!invert"
        :class="{'bg-amber-500': user?.pause, 'bg-emerald-400': !user?.pause}"
      )
      .text-gray-800.font-plus.font-semibold.text-xs {{ user?.pause ? 'Em pausa' : 'Disponível' }}
    .text-gray-500.font-plus.font-medium.mr-auto(
      class="text-[10px]"
    ) {{ user?.pause ? 'Clique aqui para sair da pausa' : 'Clique aqui para escolher uma pausa' }}
</template>

<script>
import { Tooltip } from 'floating-vue';
import cservice from '@/services/cservice';
export default {
  components: {
    VTooltip: Tooltip,
  },
  data() {
    return {
      duration: 0,
      timestamp: null,
      totalPauseTime: 0,
      timeLimitInMinutes: 0,
      timeLimit: null,
      lastMinute: 0,
      exceeded: false,
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false,
      pauseClock: null,
      pauseName: '',
      progress: 0,
      clock: null,
    };
  },
  computed: {
    isPaused() {
      return this.user.pause;
    },
  },
  watch: {
    isPaused(newStatus, oldStatus) {
      try {
        this.setup();
      } catch (error) {}
    },
  },
  methods: {
    async setAvailable(inputId) {
      if (this.user.profile !== 'p_agent') {
        await cservice.setState('enabled');
        this.$store.commit('user/SET_USER', res.data);
        await this.$store.dispatch('performance/getUserWorkgroups', this.$http);
        this.$store.commit('SET_PAUSE', {});
        this.pauseSelect = false;
        const res = await this.$http.put(`${this.requestLinks.cservice}/companies/users/unpause`);
      }
    },
    confirmPause() {
      if (this.user?.pause) return this.setAvailable();
      this.$store.commit('SET_MODAL', { name: 'confirmPause' });
    },
    async setup() {
      const res = await this.$http.get(`${this.requestLinks.cservice}/companies/availability-records/pause`);
      const pauseTimeLimit = parseInt(res.data.limit);
      const pauseTimeUsed = parseInt(res.data.time_today);

      this.$store.commit('SET_PAUSE', res.data);

      this.pauseName = res.data.pause_name;
      this.timeLimit = `${res.data.limit / 60}min`;
      this.timeLimitInMinutes = pauseTimeLimit / 60;
      clearInterval(this.clock);
      let start = this.$moment(res.data.start_date).tz(this.user.timezone);

      this.clock = setInterval(() => {
        const end = this.$moment().tz(this.user.timezone);
        const diff = end.diff(start);
        const diffAsDays = Math.floor(this.$moment.duration(diff).asDays());
        const pausedTime = this.$moment.utc(diff).valueOf() / 1000;

        if (diffAsDays <= 0) this.timestamp = this.$moment.utc(pausedTime * 1000).format('HH:mm:ss');
        else this.timestamp = this.$moment.utc(pausedTime * 1000).format('D[d] h[h] m[m]');
        this.totalPauseTime = pausedTime + pauseTimeUsed;
        this.progress = (this.totalPauseTime / pauseTimeLimit) * 100;
        if (this.totalPauseTime > pauseTimeLimit) {
          this.exceeded = true;
        }
      }, 1000);
    },
    setRingProgress(progress) {
      let circle = document.querySelector('.progress__circle');
      let radius = circle.r.baseVal.value;
      let circumference = radius * 2 * Math.PI;
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = `${circumference}`;

      function setProgress(percent) {
        const offset = circumference - circumference * (percent / 100);
        circle.style.strokeDashoffset = offset;
      }

      setProgress(progress);
    },
  },
  beforeDestroy() {
    clearInterval(this.clock);
  },
  async created() {
    try {
      await this.setup();
    } catch (e) {
      this.$log.error(e);
    }
  },
};
</script>
