<template lang="pug">
.w-full.relative
  .flex.flex-col.mb-2.items-start
    p.font-semibold.text-gray-500.font-inter(v-if="label") {{ label }}
    p.text-xs.text-gray-500(v-if="description") {{ description }}
  v-dropdown.w-full(placement="bottom-center", autoSize="min")
    .w-full.border.border-gray-200.rounded-md.flex.justify-between.py-3.font-inter.cursor-pointer(
      class="bg-[#F8F9FA]"
    )
      p.ml-5.text-base {{ Object.keys(contactPhoneMap).length ? `(${Object.keys(contactPhoneMap).length}) Contato(s) selecionado(s)` : 'Selecione um ou mais contatos' }}
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    template(#popper)
      .p-2
        slot(
          name="search"
          :search="handleSearchContact"
        )
        .flex.flex-col.max-h-60.overflow-y-scroll.mt-2.items-start.justify-start
          div.w-full(
            v-for="contact in contacts",
            :key="contact.id",
            @click="select(contact)"
          )
            slot(
              name="items",
              :contact="contact",
              :selected="contactPhoneMap[contact.contactId]?.number == contact.number"
            )
          .flex.p-2(v-if="loading")
            loading-ring
          observer(
            v-else 
            @intersect="loadMoreContactsPhones"
          )
          p.font-plus.text-sm.text-center.py-2.text-gray-500(v-if="!contacts.length") Nenhum contato encontrado
        .flex.w-full.justify-center.gap-2.pt-2
          slot(
            name="options"
            :selectAll="selectAll"
            :deselectAll="deselectAll"
          )
</template>

<script>
import { hideAllPoppers, Dropdown } from 'floating-vue';
import contactList from '@/services/contact-list';
import LoadingRing from '../LoadingRing.vue';
import Observer from '../Observer.vue';
import { debounce } from '@/helpers';

export default {
  props: ['description', 'label', 'selection-limit', 'custom-payload'],
  components: {
    vDropdown: Dropdown,
    LoadingRing,
    Observer,
  },
  data() {
    return {
      search: '',
      lastRequestLength: 0,
      offset: 0,
      loading: false,
      selection: [],
      contacts: [],
      contactPhoneMap: {},
    };
  },
  watch: {
    customPayload() {
      this.searchContactPhone();
    },
    contactPhoneMap(val) {
      return this.$emit('selected-contacts-list', val);
    },
    search() {
      this.searchContactPhone();
    },
  },
  methods: {
    searchContactPhone: debounce(async function () {
      this.contacts = [];
      this.offset = 0;
      this.lastRequestLength = 0;
      await this.getContactsPhones();
    }, 500),
    async loadMoreContactsPhones() {
      if (this.lastRequestLength < 20) return;
      this.offset = this.offset + 20;
      await this.getContactsPhones();
    },
    async getContactsPhones() {
      const { getContactsPhones } = contactList;
      if (this.loading) return;
      this.loading = true;
      try {
        const payload = {
          companyId: this.user.company_id,
          limit: 20,
          orderBy: 'name',
          offset: this.offset,
          ...this.customPayload,
        };
        if (this.search) {
          payload.filter = this.search.toLowerCase();
        }
        const data = await getContactsPhones(payload);
        this.contacts = [...this.contacts, ...data.phones];
        this.lastRequestLength = data.phones.length;
      } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possível carregar os usuários');
      } finally {
        this.loading = false;
      }
    },
    select(contact) {
      const contactPhoneMapCopy = { ...this.contactPhoneMap };

      if (!this.contactPhoneMap[contact.contactId] || this.contactPhoneMap[contact.contactId]?.number != contact.number)
        contactPhoneMapCopy[contact.contactId] = {
          number: contact.number,
          name: contact.contact.name,
          numberId: contact.id,
        };

      if (this.contactPhoneMap[contact.contactId]?.number == contact.number)
        delete contactPhoneMapCopy[contact.contactId];

      return (this.contactPhoneMap = contactPhoneMapCopy);
    },
    selectAll() {
      this.deselectAll();
      for (const contact of this.contacts) {
        if (Object.keys(this.contactPhoneMap).length == this.selectionLimit) return;
        this.select(contact);
      }
    },
    deselectAll() {
      this.contactPhoneMap = {};
    },
    handleSearchContact(input) {
      this.search = input;
    },
  },
  async created() {
    await this.getContactsPhones();
  },
};
</script>
