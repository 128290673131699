<template lang="pug">
.w-full.flex.items-start.justify-start(style="height: calc(100vh - 5rem)", class="dark:selection:bg-brand-300")
  .bg-gray-100.pl-4.flex.h-full.pt-6.font-inter.w-96.items-start.rounded-tl-3xl
    .flex.flex-col.items-start.h-full.justify-start.w-full.gap-2
      .flex.w-full.justify-between.items-center.pb-4.pr-4.gap-2.border-b
        .font-titillium.text-zinc-700.text-2xl.font-extrabold.pl-4 Campanhas
        i.material-icons.notranslate.text-1xl.bg-brand.text-white.cursor-pointer.transition-all.h-8.w-8.flex.justify-center.rounded-full.select-none(
          class="hover:bg-brand-700 active:scale-90 dark:invert",
          title="Nova campanha",
          v-if="user.profile != 'p_agent' && isWhatsAppConfigured"
          @click='showCreateCampaignModal = true;'
        ) add
        create-campaign-modal(v-if="showCreateCampaignModal" @close="showCreateCampaignModal = false")
      chat-campaigns-panel
  keep-alive
    chat-campaigns-container
</template>

<script>
import ChatCampaignsContainer from './components/ChatCampaignsContainer.vue';
import CreateCampaignModal from '@/components/Modals/CreateCampaign.vue';
import ChatCampaignsPanel from './components/ChatCampaignsPanel.vue';
import ChatConfig from '@/services/chat-config';
import user from '@/store/user';

export default {
  title: 'Opens - Campanhas',
  components: {
    ChatCampaignsContainer,
    CreateCampaignModal,
    ChatCampaignsPanel,
  },
  data() {
    return {
      showCreateCampaignModal: false,
      isWhatsAppConfigured: false,
    };
  },
  methods: {
    async getWhatsappConfigured() {
      const { getConfigsByProviderId } = ChatConfig;

      try {
        const data = await getConfigsByProviderId(process.env.VUE_APP_WHATSAPP_PROVIDER_CONFIG_ID, { inbound: true });
        const hasAnyWhatsappNumberConfigured = data.configurations.length;
        if (hasAnyWhatsappNumberConfigured) this.isWhatsAppConfigured = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    await this.getWhatsappConfigured();
  },
};
</script>
