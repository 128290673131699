<template lang="pug">
.flex.justify-between.w-full.items-center
  .flex.gap-2.items-center.justify-center
    p.font-plus.text-white
      slot
    .w-1.h-1.rounded-full.bg-gray-500
    p.font-plus.text-gray-500.text-xs SIP {{ this.user.endpoint }}
  .flex.gap-1
    f-tooltip
      .material-icons.notranslate.text-lg.cursor-pointer.transition-all(
        class="hover:opacity-50"
      ) info
      template(#popper)
        device-status-info
    .material-icons.notranslate.text-lg.cursor-pointer.transition-all(
      class="hover:opacity-50"
      v-tooltip="'Fechar dialer'"
      @click="handleClose"
    ) remove
</template>
<script>
import DeviceStatusInfo from '@/components/Dialer/dialer-device-status-info.vue';
import { Tooltip } from 'floating-vue';

export default {
  components: {
    FTooltip: Tooltip,
    DeviceStatusInfo,
  },
  methods: {
    handleClose() {
      this.$store.commit('dialer/setIsDialerOpen', false);
    },
  },
};
</script>
