<template lang="pug">
Overlay
  template(v-slot:content)
    .bg-white.flex.flex-col.py-4.px-6(class='w-[35rem]')
      .flex.space-between.w-full.py-4.border-b.border-zinc-200
        h4.text-2xl.text-gray-400.font-extrabold Transferir Tópico
        i.cancel.material-icons.notranslate.cursor-pointer(
          @click="$store.commit('modal/setActiveModal', '')"
        ) &#xe5cd
      .flex.flex-col.justify-center.items-center.w-full
        .flex.flex-col.justify-start.items-start.mb-3.w-full.m-4.w-full
          p.text-xs.text-zinc-400 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          
          .flex.mt-4.mb-2.py-2.px-4.bg-zinc-100.border.w-full.rounded.justify-start
            i.material-icons.notranslate.mr-2 search
            input(placeholder="Procurar tópico").text-zinc-400.bg-transparent
          .flex.flex-col.my-4.w-full
            ul.max-h-60.overflow-y-auto.w-full
              li.mb-3 
                .px-4.w-full.h-16.bg-zinc-100.rounded.border.flex.justify-between.cursor-pointer(class="hover:bg-zinc-50")
                  .flex 
                    p.text-sm.text-zinc-400.font-bold(class='before:mr-1 before:content-[""] before:inline-block before:w-2 before:h-2 before:bg-emerald-300 before:rounded-full') Tópico Zero
                  .flex.flex-col
                    .flex.justify-center.items-center
                      .flex.items-center.justify-center.w-9.h-9.-mx-2.overflow-hidden.rounded-full.border-2.border-white
                        img(src="https://images.unsplash.com/photo-1548544149-4835e62ee5b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=140&q=80")
                      .flex.items-center.justify-center.w-9.h-9.-mx-2.overflow-hidden.rounded-full.border-2.border-white
                        img(src="https://images.unsplash.com/photo-1548544149-4835e62ee5b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=140&q=80")
                      .flex.items-center.justify-center.w-9.h-9.-mx-2.overflow-hidden.rounded-full.border-2.border-white.bg-zinc-600
                        p.text-white.text-sm +10
                    p.text-zinc-400.text-center.text-xs Usuarios disponiveis
              li.mb-3 
                .px-4.w-full.h-16.bg-zinc-100.rounded.border.flex.justify-between.opacity-50
                  .flex 
                    p.text-sm.text-zinc-400.font-bold(class='before:mr-1 before:content-[""] before:inline-block before:w-2 before:h-2 before:bg-zinc-300 before:rounded-full') Tópico Zero
                  .flex.flex-col
                    p.text-zinc-500.text-center.text-xs Nenhum usuario disponível
        .flex.w-full.items-start.justify-center
          div
            button.py-2.px-4.mr-10.text-sm.font-inter(
              @click="$store.commit('modal/setActiveModal', '')"
            ) Cancelar
          div
            confirm-button.w-36(@click="$store.commit('modal/setActiveModal', '')") TRANSFERIR
</template>

<script>
import Overlay from '@/components/Modals/Overlay.vue';
import TagInputChat from '@/components/Inputs/TagInputChat.vue';
import ConfirmButton from '@/components/Buttons/confirm-button.vue';

export default {
  components: {
    Overlay,
    TagInputChat,
    ConfirmButton,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
  },
  methods: {},
};
</script>
