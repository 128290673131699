<template lang="pug">
div.divide-y.divide-zinc-600(class="dark:invert")
  div.pb-2
    p.text-zinc-500.font-extrabold.text-sm.text-marker Conexão

    p.flex.justify-start.w-full(v-if="isWebRTC")
      span.text-xs.text-zinc-400.text-marker Conectado:
      span.ml-2.text-xs(
        v-show='!isUpdating'
        :class="webphoneStatus.agentConnected ? 'text-emerald-600' : 'text-red-500'",
      ) {{ isAgentConnected }}

    p.flex.justify-start(v-if="isWebRTC")
      span.text-xs.text-zinc-400.text-marker Estado:
      span.ml-2.text-xs(
        v-show='!isUpdating'
        :class="isAgentStateOk ? 'text-emerald-600' : 'text-red-500'",
      ) {{ agentState }}

    p.flex.justify-start(v-if="!isWebRTC")
      span.text-xs.text-zinc-400.text-marker Registro:
      span.ml-2.text-xs(
        v-show='!isUpdating'
        :class="isRegistered ? 'text-emerald-600' : 'text-red-500'",
      ) {{ isRegistered ? "Ok" : "Sem registro" }}

  div.py-2
    p.text-zinc-500.font-extrabold.text-sm.text-marker Transporte
    p.flex.justify-start(v-if="isWebRTC")
      span.text-xs.text-zinc-400.text-marker Conectado:
      span.ml-2.text-xs(
        v-show='!isUpdating'
        :class="webphoneStatus.transportConnected ? 'text-emerald-600' : 'text-red-500'",
      ) {{ webphoneStatus.transportConnected ? "Sim" : "Não"}}
    p.flex.justify-start(v-if="isWebRTC")
      span.text-xs.text-zinc-400.text-marker Estado:
      span.ml-2.text-xs(
        v-show='!isUpdating'
        :class="isTransportStateOk ? 'text-emerald-600' : 'text-red-500'",
      ) {{ transportState }}

    p.flex.justify-start.gap-2
      span.text-xs.text-zinc-400.text-marker Protocolo:
      span.text-emerald-600.text-xs {{ transportMethod }}

  div.py-2
    p.text-zinc-500.font-extrabold.text-sm.text-marker Latência
    p.flex.justify-start
      span.text-xs.text-zinc-400.text-marker Tempo real:
      span.ml-2.text-xs(
        v-show='!isUpdating',
        class="dark:invert"
      ) {{ cstateLatestLatencyValue }}ms
    p.flex.justify-start
      span.text-xs.text-zinc-400.text-marker Tempo real (média):
      span.ml-2.text-xs(
        v-show='!isUpdating',
        class="dark:invert"
      ) {{ cstateLatency }}ms

    p.flex.justify-start
      span.text-xs.text-zinc-400.text-marker Ultima medição:
      span.ml-2.text-xs(
        v-show='!isUpdating',
        class="dark:invert"
      ) {{ cstateLatencyPingDate }}


</template>

<script>
import useGetDeviceRegistryMixin from '@/mixins/cstate/use-get-device-registry';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';

export default {
  mixins: [useGetDeviceRegistryMixin],
  props: {
    isUpdating: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vButtonSecondary,
  },
  computed: {
    isWebRTC() {
      return this.configuration.transport === 'transport-wss';
    },
    configuration() {
      return this.$store.getters['sip/configuration'];
    },
    webphoneStatus() {
      return this.$store.getters['sip/webphoneStatus'];
    },
    isConnected() {
      return this.$store.getters['cstate/isConnected'];
    },
    isRegistered() {
      return this.$store.getters['cstate/IS_DEVICE_CONNECTED'];
    },
    isAgentConnected() {
      if (this.webphoneStatus.agentConnected) return 'Sim';
      return 'Não';
    },
    agentState() {
      if (this.webphoneStatus.agentState === 'Started') return 'Inicializado';
      else return 'Ocioso';
    },
    isAgentStateOk() {
      if (this.webphoneStatus.agentState === 'Started') return true;
      return false;
    },
    transportState() {
      if (this.webphoneStatus.transportState === 'Connected') return 'Conectado';
      if (this.webphoneStatus.transportState === 'Connecting') return 'Conectando';
      if (this.webphoneStatus.transportState === 'Disconnecting') return 'Desconectando';
      if (this.webphoneStatus.transportState === 'Disconnected') return 'Desconectado';
      return 'Desconectado';
    },
    isTransportStateOk() {
      if (this.webphoneStatus.transportState === 'Connected') return true;
      return false;
    },
    transportMethod() {
      if (this.configuration.transport === 'transport-wss') return 'WebRTC';
      if (this.configuration.transport === 'transport-tls') return 'SIP';
      return 'Indefinido';
    },
    cstateLatencyPingDate() {
      const date = this.$store.getters['cstate/lastPingDate'];
      return this.$moment(date).format('HH:mm:ss');
    },
    cstateLatency() {
      const latencyArray = this.$store.getters['cstate/latency'];
      const totalLatency = latencyArray?.reduce((total, lantency) => total + lantency, 0);
      if (!totalLatency) return '-';
      return Math.floor(totalLatency / latencyArray.length);
    },
    cstateLatestLatencyValue() {
      const latencyArray = this.$store.getters['cstate/latency'];
      if (!latencyArray) return '-';
      return latencyArray[latencyArray.length - 1];
    },
  },
};
</script>
