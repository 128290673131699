<template lang="pug">
v-dropdown(distance="12")
  .flex.h-7.relative.cursor-pointer(@click="switchNotifications")
    .w-3.h-3.bg-red-600.rounded-full.absolute.top-1.right-2.flex.text-white.animate-pulse.z-10(
      v-if="unseen"
      class="text-[10px]"
    ) {{ unseen }}
    .w-3.h-3.bg-red-600.rounded-full.absolute.top-1.right-2.animate-pulse.z-10(
      v-else-if="unread"
    )
    a.material-icons-outlined.notranslate.text-gray-600.cursor-pointer.transition-all(
      class="hover:opacity-60"
    ) notifications
  template(#popper)
    NotificationContainer(
      :notifications="notifications",
    )
</template>

<script>
import notifyService from '@/services/notify.js';
import NotificationContainer from '@/components/Modals/Notification/index.vue';

export default {
  components: {
    NotificationContainer,
  },

  data() {
    return {
      isNotificationVisible: false,
      notifications: [],
    };
  },
  methods: {
    switchNotifications() {
      if (this.isNotificationVisible) {
        if (this.unseen) {
          notifyService.markAllAsSeen({ userId: this.user.id });
          this.notifications.forEach((n) => {
            n.seen = true;
          });
        }
      } else this.$store.commit('modal/setActiveModal', '');
      this.isNotificationVisible = !this.isNotificationVisible;
    },
    async getNotifications() {
      try {
        const data = await notifyService.getMostRecentNotifications({
          userId: this.user.id,
        });
        this.notifications = data;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  computed: {
    unseen() {
      return this.notifications.filter((notification) => {
        return !notification.seen;
      }).length;
    },
    unread() {
      return this.notifications.filter((notification) => {
        return !notification.read;
      }).length;
    },
  },
  watch: {
    unseen(current, previous) {
      if (!this.unseen) {
        this.$store.commit('user/SET_NEWACTIVITY', false);
      }
      if (
        this.notifications.filter((notification) => {
          return !notification.seen;
        })[0]?.type == 'activity'
      ) {
        this.$store.commit('user/SET_NEWACTIVITY', true);
      }
    },
  },
  async created() {
    try {
      await this.getNotifications();
      const eventSource = notifyService.connectSSE({ userId: this.user.id });
      eventSource.onmessage = async (e) => {
        await this.getNotifications();
      };
    } catch (error) {}
  },
};
</script>
