<template lang="pug">
dialer-layout
  template(#header)
    dialer-header Recebendo chamada
  template(#body)
    .flex.flex-col.gap-4.items-start
      .flex.gap-2
        .h-10.w-10.rounded-full.bg-slate-700.flex.items-center.justify-center.overflow-hidden
          avatar(
            v-if="!identityContact.unknown"
            :src="identityContact.avatarUrl",
            :avatarId="identityContact.id",
          )
          img.h-full(
            v-else
            src="@/assets/images/default_pfp.png"
          )

        .flex.flex-col.gap-0.items-start
          p.text-white.text-sm.font-semibold.font-plus {{ identityContact?.name }}
          p.text-xs.text-slate-500.font-plus {{ identityContact?.organization || "Sem organização" }}

      .flex.gap-2(v-if="loadingCall")
        loading-ring
        
      .flex.gap-2(v-else)
        dialer-option(
          icon="phone",
          green,
          @click="pickupCall",
          v-if="isWebRTC"
        )

        dialer-option(
          icon="call_end",
          red,
          @click="hangUpCall",
          v-if="isWebRTC"
        )
</template>

<script>
import DialerLayout from '@/components/Dialer/dialer-layout.vue';
import DialerHeader from '@/components/Dialer/dialer-header.vue';
import DialerOption from '@/components/Dialer/dialer-option.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import { getUserRingingSound } from '@/services/cservice';
import LoadingRing from '@/components/LoadingRing.vue';
import Avatar from '@/components/Avatars/avatar.vue';

export default {
  mixins: [UserOrContactMixin],
  components: {
    DialerLayout,
    DialerOption,
    DialerHeader,
    LoadingRing,
    Avatar,
  },
  data() {
    return {
      cserviceBaseUrl: process.env.VUE_APP_CSERVICE,
      contactListBaseUrl: process.env.VUE_APP_CLIST,
      translateCServiceStatus: {
        enabled: 'Disponível',
        disabled: 'NaoPerturbe',
        online: 'Disponível',
        auto: 'Disponível',
      },
      loadingCall: false,
      failedImg: false,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sip() {
      return this.$store.getters['sip/session'];
    },
    isTransfer() {
      return this?.data?.event?.toLowerCase() === 'transfer';
    },
    agentHasToFillMandatoryTagAfterCall() {
      return false;
    },
    fromId() {
      return this.data?.fromId;
    },
    from() {
      return this.data?.from;
    },
  },
  methods: {
    async hangUpCall() {
      if (this.loadingCall) return;
      this.loadingCall = true;
      try {
        await this._hangUpCall();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingCall = false;
        this.$emit('hangup-call');
      }
    },
    pickupCall() {
      if (this.agentHasToFillMandatoryTagAfterCall || this.loadingCall) return;
      this.loadingCall = true;
      this.sip.session.accept(this.sip.options);
    },
    playAudio() {
      if (!this.isWebRTC) return;
      const volume = this.$store.getters['userPreferences/getAudioVolume'];
      const audio = this.$store.getters['cstate/ringingSound'];
      audio.volume = volume;
      audio.play();
    },
    async identifyCall() {
      await this.setContactOrOperator({
        id: this.fromId,
        number: this.from,
      });
    },
  },
  mounted() {
    try {
      const localStorageUserRingingSound = localStorage.getItem(`@yunique:ringing-sound:${this.user.id}`);
      if (localStorageUserRingingSound) {
        const ringingSounds = this.$store.getters['cstate/ringingSounds'];
        const selectedRingingSound = ringingSounds[localStorageUserRingingSound] || ringingSounds['default'];

        this.$store.commit('cstate/setRingingSound', selectedRingingSound);
      } else {
        getUserRingingSound(this.user.id).then((data) => {
          if (data.ringing_sound) {
            const ringingSounds = this.$store.getters['cstate/ringingSounds'];
            const selectedRingingSound = ringingSounds[data.ringing_sound] || ringingSounds['default'];

            this.$store.commit('cstate/setRingingSound', selectedRingingSound);
            localStorage.setItem(`@yunique:ringing-sound:${this.user.id}`, data.ringing_sound);
          }
        });
      }
    } catch (e) {}
    this.playAudio();
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.addEventListener('ended', this.playAudio);
  },
  beforeDestroy() {
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.removeEventListener('ended', this.playAudio);
    audio.pause();
    audio.currentTime = 0;
  },
  created() {
    this.identifyCall();
  },
  watch: {
    data: {
      handler(v) {
        this.identifyCall();
      },
      immediate: false,
    },
  },
};
</script>
