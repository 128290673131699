<template lang="pug">
modal(@click="$emit('close', false)")
  modal-body-no-footer.rounded-md(class="w-[40rem]")
    template(v-slot:header)
      .flex
        v-heading Como funciona o gráfico
        .material-icons.notranslate.ml-auto.cursor-pointer.text-gray-400(@click="$emit('close', false)") close
    template(v-slot:body)
      .flex.w-full.gap-2.flex-col
        p O gráfico é baseado nos três status: <b>Criados</b>, <b>Entregues</b> e <b>Convertidos</b>. No exemplo abaixo temos 30 mensagens a serem enviadas (Criados), 20 destas mensagens foram entregues (Entregues) e 10 foram respondidas (Convertidos).
        p Além disso, o gráfico possui as informações <b>Total</b> e <b>Redução</b>. O <b>total</b> é o total em porcentagem do respectivo status, já a <b>redução</b> é a porcentagem de quanto um status foi reduzido de uma etapa para outra. No exemplo abaixo podemos ver que das 30 mensagens a serem entregues até o momento somente 20 foram entregues, causando assim uma redução de 33%
      .flex.gap-2.mt-2.border.p-2.rounded-md.border-dashed.flex-col

        funnel-chart(
          :queued="30",
          :sent="20",
          :converted="10",
        )
          template(v-slot:title)
            h3.font-bold.text-gray-600.text-lg.text-center.pb-4 Status das mensagens enviadas para os contatos

        .flex.w-full.gap-2
          .border.rounded-md.bg-white.justify-start.items-center.flex.w-full.p-2.cursor-help.opacity-80(class="hover:opacity-100 transition-all" v-tooltip="'Quantidade de mensagens a serem enviadas'")
            .flex.bg-blue-200.w-12.h-12.p-3.rounded-full.mr-3
              .material-icons.notranslate.text-blue-600 cloud_upload
            .flex.flex-col.items-start
              p.font-plus.font-semibold Criados
              p.font-plus.text-xl.font-bold 30
          .border.rounded-md.bg-white.justify-start.items-center.flex.w-full.p-2.cursor-help.opacity-80(class="hover:opacity-100 transition-all" v-tooltip="'Quantidade de mensagens enviadas'")
            .flex.bg-amber-200.w-12.h-12.p-3.rounded-full.mr-3
              .material-icons.notranslate.text-amber-600 mail
            .flex.flex-col.items-start
              p.font-plus.font-semibold Entregues
              p.font-plus.text-xl.font-bold 20
          .border.rounded-md.bg-white.justify-start.items-center.flex.w-full.p-2.cursor-help.opacity-80(class="hover:opacity-100 transition-all" v-tooltip="'Quantidade de mensagens respondidas pelos contatos'")
            .flex.bg-emerald-200.w-12.h-12.p-3.rounded-full.mr-3
              .material-icons.notranslate.text-emerald-600 sync
            .flex.flex-col.items-start
              p.font-plus.font-semibold Convertidos
              p.font-plus.text-xl.font-bold 10
</template>

<script>
import ModalBodyNoFooter from '@/components/Modals/ModalBodyNoFooter.vue';
import VHeading from '@/components/Text/v-heading.vue';
import Modal from '@/components/Modals/Modal.vue';
import FunnelChart from './FunnelChart.vue';

export default {
  components: {
    ModalBodyNoFooter,
    FunnelChart,
    VHeading,
    Modal,
  },
};
</script>
