import { render, staticRenderFns } from "./CallEnded.vue?vue&type=template&id=2ddeaae1&scoped=true"
import script from "./CallEnded.vue?vue&type=script&lang=js"
export * from "./CallEnded.vue?vue&type=script&lang=js"
import style0 from "./CallEnded.vue?vue&type=style&index=0&id=2ddeaae1&prod&scoped=true&lang=css"
import style1 from "./CallEnded.vue?vue&type=style&index=1&id=2ddeaae1&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddeaae1",
  null
  
)

export default component.exports