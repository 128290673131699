<template lang="pug">
.flex.rounded-md.w-full.bg-gray-800.p-2.items-center.justify-between
  .flex.gap-2
    .flex.rounded-full.items-center.justify-center.bg-gray-700.overflow-hidden.border.border-white(
      class="min-h-[2.5rem] max-h-[2.5rem] min-w-[2.5rem] max-w-[2.5rem]"
      v-if="$slots.avatar"
    )
      slot(name="avatar")

    .flex.flex-col.items-start
      p.font-plus.text-white.truncate(class="max-w-[10rem]", v-tooltip="name") {{ name }}
      p.font-plus.text-gray-400.text-xs {{ endpoint }}

  dialer-option(
    icon="phone"
    light-green
    @click="$emit('make-call')",
    v-if="isWebRTC",
  )
    template(#tooltip)
      p.font-plus.text-white Ligar para {{ endpoint }}
</template>

<script>
import userOrContactMixin from '@/mixins/user-or-contact.mixin';
import DialerOption from '@/components/Dialer/dialer-option.vue';

export default {
  mixins: [userOrContactMixin],
  components: {
    DialerOption,
  },
  props: {
    name: {
      type: String,
      default: 'Desconhecido',
    },
    endpoint: {
      type: String,
      default: '0000000000',
    },
  },
};
</script>
