<template lang="pug">
modal(@click="$emit('close', false)")
  modal-body(class="w-[40rem]")
    template(v-slot:header)
      .flex
        v-heading Iniciar campanha
        .material-icons.notranslate.ml-auto.cursor-pointer.text-gray-400(@click="$emit('close', false)") close
    template(v-slot:body)
      .flex.flex-col.gap-4.w-full.items-start(v-if="isProviderSelection")
        p Selecione o provedor que será utilizado nesta campanha
        .flex.flex-col.w-full.gap-4
          .flex.w-full.rounded-md.border.border-gray-300.p-3.justify-start.gap-3.cursor-pointer(
            @click="selectedProvider = 'whatsapp'"
            :class="{'border-brand-400' : selectedProvider == 'whatsapp'}"
            class="transition-all hover:bg-gray-100 active:scale-90"
          )
            img.w-8(src="@/assets/images/whatsapplogo.png")
            p WhatsApp
            .flex.w-6.h-6.border.rounded-full.items-center.justify-center.ml-auto
              .flex.w-3.h-3.rounded-full.bg-brand-400(v-if="selectedProvider == 'whatsapp'")
      .flex.flex-col.w-full(v-if="selectedProvider == 'whatsapp' && isFirstStep")
        .flex.gap-2.mb-4
          .flex.h-8.w-8.border-2.rounded-full.border-purple-400 1
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400 2
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400 3
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400 4
        .flex.flex-col.gap-6.w-full.max-h-96.overflow-auto
          .flex.border.rounded-md.flex-col.p-2.gap-2.w-full.items-start
            span.font-inter.text-sm.text-gray-400.font-medium.w-full Escolha o nome desta campanha
              v-input(
                icon="campaign"
                label="Nome da campanha"
                v-model="campaignName"
              )
            span.font-inter.text-sm.text-gray-400.font-medium.w-full Escolha a partir de qual número você iniciará a campanha
              v-dropdown(:placeholder="!waba.id ? 'Selecione o número' : waba.configurations.phone").w-full
                div.max-h-56.w-full.overflow-x-hidden.overflow-y-auto
                  .p-2.flex.w-full.justify-between.cursor-pointer.rounded.gap-3.border.border-white(
                    v-for="(providerConfig, index) in wabas",
                    :class="providerConfig.id == waba.id ? 'border-brand-500' : ''"
                    @click="selectProviderConfig(providerConfig)",
                    class="transition-all dark:invert hover:bg-gray-100 active:scale-90"
                  )
                    .font-inter.text-gray-800.text-base(class="dark:invert") {{ providerConfig.configurations.phone }}
                    .font-inter.text-gray-400.text-base.ml-auto(class="dark:invert") Waba id: {{ providerConfig.configurations.wabaId }}
                    .h-6.w-6.border.rounded-full.flex.items-center.justify-center(
                      :class="providerConfig.id == waba.id ? 'bg-brand-500 border-brand-500' : ''"
                    )
                      .material-icons.notranslate.text-sm.text-white check
            .font-inter.text-sm.text-gray-400.font-medium.w-full Data de termino da campanha
            v-button(@click='isSelectingCampaignExpirationDate = true' v-if="!selectedDate") Selecionar data
            .flex.gap-2(v-else)
              p.text-red-500 Essa campanha ira expirar {{ $moment(selectedDate).format("DD/MM/yyyy [as] HH:mm") }}
              v-button(
                small
                v-if="selectedDate"
                @click='isSelectingCampaignExpirationDate = true'
              ) Alterar data
            modal(mo2 v-if="isSelectingCampaignExpirationDate" @click='isSelectingCampaignExpirationDate = false')
              modal-body(class="w-[40rem]")
                template(v-slot:header)
                template(v-slot:body)
                  .flex.flex-col.gap-6.items-start
                    .h-full.border-2.border-amber-500.p-4.rounded-md.gap-4.bg-amber-50.box-border
                      p.font-semibold.text-amber-500 Atenção!
                      p.text-zinc-400.max-w-3xl Após a data selecionada a campanha sera desativada e qualquer novo contato sera redirecionado para seu fluxo padrão de atendimento

                    v-date-picker.w-full(
                      v-model="selectedDate"
                      color="orange"
                      mode="dateTime"
                      is24hr
                      :min-date="new Date()"
                    )
                template(v-slot:footer)
                  .flex.gap-4
                    v-button(@click="isSelectingCampaignExpirationDate = false") Salvar
      .flex.flex-col.w-full(v-if="selectedProvider == 'whatsapp' && isSecondStep")
        .flex.gap-2.mb-4
          .flex.h-8.w-8.border.rounded-full.border-gray-400.cursor-pointer(@click="previousStep(1)") 1
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border-2.rounded-full.border-purple-400 2
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400 3
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400 4
        .flex.flex-col.justify-start.items-start.mb-6.w-full.m-4.w-full
          .h-full.border-2.border-amber-500.p-4.rounded-md.gap-4.bg-amber-50.box-border(v-if="user.profile == 'p_manager'")
            p.font-semibold.text-amber-500 Atenção!
            p.text-zinc-400.max-w-3xl Os usuários de perfil gestor só poderão selecionar os grupos de chat que estão inseridos e as pessoas que estão inseridas nos grupos de trabalho que ele gerencia
          span.font-inter.text-sm.text-gray-400.font-medium.w-full.mt-2 Escolha o tipo do responsável para esta campanha
            v-dropdown(:placeholder="campaignResponsibleType ? campaignResponsibleType : 'Selecione o tipo do responsável'")
              dropdown-item(
                v-close-popper
                label="Grupo de atendimento",
                hide-icon
                :selected="campaignResponsibleType == 'Grupo de atendimento'"
                @click="campaignResponsibleType == 'Grupo de atendimento' ? campaignResponsibleType = '' : campaignResponsibleType = 'Grupo de atendimento'"
              )
              dropdown-item(
                v-close-popper
                label="Pessoa",
                hide-icon
                :selected="campaignResponsibleType == 'Pessoa'"
                @click="campaignResponsibleType == 'Pessoa' ? campaignResponsibleType = '' : campaignResponsibleType = 'Pessoa'"
              )
              dropdown-item(
                v-close-popper
                label="Atribuir a mim mesmo",
                hide-icon
                :selected="campaignResponsibleType == 'Atribuir a mim mesmo'"
                @click="campaignResponsibleType == 'Atribuir a mim mesmo' ? campaignResponsibleType = '' : campaignResponsibleType = 'Atribuir a mim mesmo'"
              )

          span.font-inter.text-sm.text-gray-400.font-medium.w-full(v-if="campaignResponsibleType != 'Atribuir a mim mesmo' && campaignResponsibleType") Escolha o(a) {{ campaignResponsibleType.toLowerCase() }} responsável por essa campanha
            v-dropdown(:placeholder="campaignResponsible ? campaignResponsible.name : `Selecione o(a) ${campaignResponsibleType.toLowerCase()}`")
              v-input.mb-2(
                label="Buscar"
                v-model="searchResponsible"
              )
              loading-ring(v-if="loadingResponsibles")
              dropdown-item(
                hide-icon
                v-close-popper
                v-if="!loadingResponsibles"
                v-for="responsible in responsiblesList"
                @click="campaignResponsible == responsible ? campaignResponsible = {} : campaignResponsible = responsible"
                :selected="responsible == campaignResponsible"
                :label="responsible.name",
                :key="responsible.id"
              )
              p.w-full.text-center.text-gray-400.p-2(v-if="!responsiblesList.length") Nenhum responsável disponível
      .flex.flex-col.w-full(v-if="selectedProvider == 'whatsapp' && isThirdStep")
        .flex.gap-2.mb-4
          .flex.h-8.w-8.border.rounded-full.border-gray-400.cursor-pointer(@click="previousStep(1)") 1
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400.cursor-pointer(@click="previousStep(2)") 2
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border-2.rounded-full.border-purple-400 3
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400 4
        .flex.flex-col.justify-start.items-start.mb-6.w-full.m-4.w-full
          mount-whatsapp-template-form(@error="handleTemplateValuesFilled" @updated="updateTemplateValue" :provider-config-id="waba.id")
      .flex.flex-col.w-full(v-if="selectedProvider == 'whatsapp' && isFourthStep")
        .flex.gap-2.mb-4
          .flex.h-8.w-8.border.rounded-full.border-gray-400.cursor-pointer(@click="previousStep(1)") 1
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400.cursor-pointer(@click="previousStep(2)") 2
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border.rounded-full.border-gray-400.cursor-pointer 3
          .flex.w-12.bg-gray-400(class="h-[1px]")
          .flex.h-8.w-8.border-2.rounded-full.border-purple-400 4
        .flex.flex-col.p-2.items-start.gap-4.w-full
          .flex.p-2.items-center.border.border-red-500.rounded-lg.w-full.justify-center.gap-2
            .material-icons.notranslate.text-red-500 warning
            p.text-red-500.font-semibold Esta operação poderá gerar custos adicionais de envio na META
          contact-phone-multi-selection-dropdown(
            description="Você só pode adicionar no máximo 100 contatos por vez."
            :selection-limit="100"
            :custom-payload="contactsPayload"
            @selected-contacts-list="handleSelectedContacts"
          )
            template(#search="{search}")
              .flex
                v-input(
                  @input="search"
                  :label="'Nome do usuário'"
                )
            template(#items="{contact, selected}")
              .flex
                contact-phone-row(
                  :data="contact"
                  :selected="selected"
                )
                v-tooltip(
                  @apply-show="getContactCampaignsInfo(contact.contact)"
                  v-if="contact?.contact?.campaigns[0]?.campaignId"
                )
                  .material-icons.px-2.cursor-help campaign
                  template(#popper)
                    p Este usuário já está em uma ou mais campanhas
                    p(v-if="loadingSelectedContactCampaignNames") Carregando...
                    div(v-else, v-html="selectedContactCampaignNames")
            template(#options="{selectAll, deselectAll}")
              .flex.flex-col.gap-2.w-full
                .flex.gap-2.justify-start.py-2.border-y.w-full
                  input(type="checkbox", v-model="includeContactsInCampaign")
                  p.text-xs.text-gray-500 Incluir contatos que já estão em uma campanha
                .flex.gap-2
                  v-button-secondary.w-full(
                    small
                    @click="deselectAll"
                  ) Desmarcar todos
                  v-button-secondary.w-full(
                    small
                    @click="selectAll"
                  ) Selecionar todos
    template(v-slot:footer)
      .flex.gap-2
        v-button-secondary(@click="$emit('close', false)") Cancelar
        v-button(v-if="!isFourthStep && !templateData.templateNeedSync" @click="nextStep") Continuar
        v-button(v-if="isFourthStep" @click="addContactsToCampaign") Criar campanha

</template>

<script>
import ContactPhoneMultiSelectionDropdown from '@/components/Dropdown/contact-phone-multi-selection-dropdown.vue';
import mountWhatsappTemplateForm from '@/components/Forms/mount-whatsapp-template-form.vue';
import ContactPhoneRow from '@/components/TablesRows/contact-phone-row.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import { Tooltip, Dropdown, hideAllPoppers } from 'floating-vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import VDropdown from '@/components/Dropdown/dropdown.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VButton from '@/components/Buttons/v-button.vue';
import VHeading from '@/components/Text/v-heading.vue';
import VInput from '@/components/Inputs/IconInput.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import contactList from 'src/services/contact-list.js';
import Modal from '@/components/Modals/Modal.vue';
import Observer from '@/components/Observer.vue';
import ChatConfig from '@/services/chat-config';
import ChatAdapter from '@/services/adapter';
import Campaign from '@/services/campaigns';
import cservice from '@/services/cservice';
import { debounce } from '@/helpers';
import 'floating-vue/dist/style.css';
import axios from 'axios';

const PageViewEnum = {
  CHAT: 0,
  CAMPAIGN: 1,
};

export default {
  title: 'Opens - Chat',
  components: {
    ContactPhoneMultiSelectionDropdown,
    mountWhatsappTemplateForm,
    FDropdown: Dropdown,
    FTooltip: Tooltip,
    LoadingComponent,
    VButtonSecondary,
    ContactPhoneRow,
    DropdownItem,
    LoadingRing,
    VDropdown,
    ModalBody,
    VHeading,
    Observer,
    VButton,
    VInput,
    Modal,
  },
  data() {
    return {
      mountedTemplateErrorMessage: 'Selecione um modelo de mensagem para continuar.',
      selectedContactCampaignNames: '',
      selectedProvider: 'whatsapp',
      campaignConversionRuleId: '',
      campaignResponsibleType: '',
      searchTemplateValue: '',
      contactSearchValue: '',
      searchResponsible: '',
      selectedDate: '',
      campaignName: '',
      currentDate: '',
      oldName: '',
      page: 1,
      loadingSelectedContactCampaignNames: false,
      isSelectingCampaignExpirationDate: false,
      includeContactsInCampaign: false,
      showCreateCampaignModal: false,
      loadingSearchTemplate: false,
      loadingResponsibles: false,
      isProviderSelection: true,
      contactConflicted: false,
      isEmptyTemplates: false,
      loadingConfig: false,
      hasVariables: false,
      isFourthStep: false,
      isSecondStep: false,
      isThirdStep: false,
      isFirstStep: false,
      search: false,
      headerVariablesValues: [],
      bodyVariablesValues: [],
      headerVariables: [],
      clistContacts: [],
      bodyVariables: [],
      responsibles: [],
      nextPages: [],
      templates: [],
      wabas: [],
      campaignResponsible: {},
      selectedContacts: {},
      templateData: {},
      campaign: {},
      client: {},
      waba: {},
      PageViewEnum: PageViewEnum,
      selectedTemplate: null,
      headerImageUrl: null,
      templateConfig: null,
      cancelToken: axios.CancelToken.source(),
    };
  },
  watch: {
    contactSearchValue(val) {
      this.searchTable();
    },
    async showCreateCampaignModal(val) {
      if (!val) {
        this.resetTemplate();
        this.templates = [];
        this.campaignName = '';
        this.isProviderSelection = true;
        this.isFirstStep = false;
        this.isSecondStep = false;
        this.isFourthStep = false;
        this.selectedProvider = 'whatsapp';
        this.whatsappPhoneNumber = '';
        this.selectedDate = '';
        this.selectedContacts = [];
        this.campaignResponsibleType = '';
      }
    },
    async campaignResponsibleType(val) {
      this.campaignResponsible = {};
      this.responsibles = [];
      if (!val) return (this.responsibles = []);
      if (val == 'Pessoa') return await this.getAllUsers();
      if (val == 'Atribuir a mim mesmo') return (this.campaignResponsible = this.user);
      return await this.getAllQueues();
    },
  },
  computed: {
    contactsPayload() {
      const payload = {
        associations: ['campaigns'],
        campaigns: { isActive: true },
        excluding: ['campaigns'],
      };
      if (!this.includeContactsInCampaign) return payload;
      delete payload.excluding;
      return payload;
    },
    containerView() {
      return this.$store.getters['chatView/getContainerView'];
    },
    panelView() {
      return this.$store.getters['chatView/getPanelView'];
    },
    panelComponent() {
      if (this.panelView === PageViewEnum.CHAT) return 'chat-rooms-panel';
      if (this.panelView === PageViewEnum.CAMPAIGN) return 'chat-campaigns-panel';
      return 'chat-rooms-panel';
    },
    containerComponent() {
      if (this.containerView === PageViewEnum.CHAT) return 'chat-rooms-container';
      if (this.containerView === PageViewEnum.CAMPAIGN) return 'chat-campaigns-container';
      return 'chat-rooms-container';
    },
    responsiblesList() {
      return this.responsibles.filter((responsible) => {
        return responsible.name.toLowerCase().includes(this.searchResponsible.toLowerCase());
      });
    },
  },
  methods: {
    async getContactCampaignsInfo(contact) {
      const { getCampaignById } = Campaign;
      if (this.loadingSelectedContactCampaignNames) return;
      this.loadingSelectedContactCampaignNames = true;
      const campaigns = contact.campaigns;
      let campaignNames = '';
      for (const campaign of campaigns) {
        try {
          const data = await getCampaignById(campaign.campaignId);
          campaignNames += `${data.name};<br>`;
        } catch (error) {
          campaignNames += `Campanha desconhecida;<br>`;
        }
      }
      this.selectedContactCampaignNames = campaignNames;
      this.loadingSelectedContactCampaignNames = false;
    },
    handleSelectedContacts(data) {
      this.selectedContacts = data;
    },
    async getAllQueues() {
      this.loadingResponsibles = true;
      try {
        let chatQueues = await ChatConfig.getAllQueues();
        chatQueues = chatQueues.sort((a, b) => a.name.localeCompare(b.name));
        if (this.user.profile != 'p_manager') return (this.responsibles = chatQueues);
        chatQueues.forEach((chatQueue) => {
          chatQueue.members.forEach((member) => {
            if (member.memberId == this.user.id) this.responsibles.push(chatQueue);
          });
        });
      } catch (e) {
        this.$toast.error('Não foi possível os grupos de atendimento');
      } finally {
        this.loadingResponsibles = false;
      }
    },
    async getUserQueueMates() {
      this.loadingResponsibles = true;
      try {
        const data = await cservice.getAllManagedUsers(this.user.id);
        let responsibles = data.map((item) => {
          return { ...item, name: item.username };
        });
        return (this.responsibles = responsibles);
      } catch (e) {
        this.$toast.error(e);
      } finally {
        this.loadingResponsibles = false;
      }
    },
    async getAllUsers() {
      this.loadingResponsibles = true;
      try {
        let data = await cservice.getAllUsers();
        data = data.sort((a, b) => a.username.localeCompare(b.username));
        data = data.filter((user) => {
          if (user.type == 'user' && (user.status == 'activated' || user.status == 'new')) return user;
        });
        data = data.map((item) => {
          return { ...item, name: item.username };
        });
        if (this.user.profile != 'p_manager') return (this.responsibles = data);
        return await this.getUserQueueMates();
      } catch (e) {
        this.$toast.error('Não foi possível carregar os usuários');
      } finally {
        this.loadingResponsibles = false;
      }
    },
    formatDateToBrasilType(date) {
      const splittedDate = date.split('-');
      const formattedDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
      return formattedDate;
    },
    handleTemplateValuesFilled(data) {
      this.mountedTemplateErrorMessage = data;
    },
    updateTemplateValue(data) {
      this.templateData = data;
    },
    selectView(type) {
      this.$store.commit('chatView/setContainerView', type);
      this.$store.commit('chatView/setPanelView', type);
    },
    async createCampaign() {
      const { createCampaign } = Campaign;
      try {
        const isoDate = this.$moment(this.selectedDate).toISOString();
        const campaignPayload = {
          companyId: this.companyId,
          name: this.campaignName,
          expiresAt: isoDate,
          createdBy: this.user.id,
          assignedTo: this.campaignResponsible.id,
          provider: 'whatsapp',
          providerId: this.waba.providerId,
          providerConfigId: this.waba.id,
          payload: {
            template: this.templateData.selectedTemplate,
            templateOptions: {
              headerVariablesValues: this.templateData.headerVariablesValues,
              bodyVariablesValues: this.templateData.bodyVariablesValues,
              buttonsVariablesValues: this.templateData.buttonsVariablesValues,
              isSupportedTemplate: this.templateData.isSupportedTemplate,
              isMediaHeader: this.templateData.isMediaHeader,
              isRequiredHeaderMedia: this.templateData.isRequiredHeaderMedia,
              headerFile: this.templateData.headerFile,
              hasVariables: this.templateData.hasVariables,
              buttonsVariables: this.templateData.buttonsVariables,
              bodyVariables: this.templateData.bodyVariables,
              headerVariables: this.templateData.headerVariables,
              ...(this.templateData?.headerFileInfos && { headerFileInfos: this.templateData.headerFileInfos }),
            },
          },
        };
        if (!this.selectedDate) delete campaignPayload.expiresAt;
        if (this.campaignConversionRuleId) campaignPayload.associatedRuleId = this.campaignConversionRuleId;
        const data = await createCampaign(campaignPayload);
        this.campaign = data;
        const currentValue = this.$store.getters['campaign/getHandleCampaignUpdate'];
        this.$store.commit('campaign/setHandleCampaignsUpdate', currentValue + 1);
        this.$store.commit('campaign/setActiveCampaignId', this.campaign.id);
        const campaignContainerView = 1;
        this.$store.commit('chatView/setContainerView', campaignContainerView);
        this.$store.commit('chatView/setPanelView', campaignContainerView);
        this.isFourthStep = true;
        this.isThirdStep = false;
        this.$toast.success('Campanha criada com sucesso!');
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async addContactsToCampaign() {
      if (!Object.keys(this.selectedContacts).length)
        return this.$toast.error('Selecione algum usuário para criar esta campanha');
      const { addContactsToCampaign } = Campaign;
      try {
        const contactsList = [];
        for (const contact in this.selectedContacts) {
          contactsList.push({
            contactId: contact,
            campaignId: this.campaign.id,
            contactName: this.selectedContacts[contact].name,
            contactEndpoint: this.selectedContacts[contact].number,
            contactEndpointId: this.selectedContacts[contact].numberId.toString(),
          });
        }
        await addContactsToCampaign(contactsList);
        this.$toast.success('Contatos adicionados com sucesso!');
        this.$emit('close', false);
        const currentValue = this.$store.getters['campaign/getHandleCampaignUpdate'];
        this.$store.commit('campaign/setHandleCampaignsUpdate', currentValue + 1);
      } catch (e) {
        this.$toast.error(e);
      }
    },
    setSelectedUsers(data) {
      this.selectedContacts = data;
    },
    dataSelected(data) {
      this.contactConflicted = false;
    },
    async intersected() {
      const { getAllContactsQuery } = contactList;
      let handleClistContacts = [];
      if (!this.contactSearchValue) {
        handleClistContacts = await getAllContactsQuery({
          offset: this.page * 20,
          limit: this.page * 20 + 20,
        });
        this.page++;
        this.clistContacts = [...this.clistContacts, ...handleClistContacts];
      }

      handleClistContacts = await contactList.getContactsByIdentifier({
        offset: this.page * 20,
        limit: this.page * 20 + 20,
        filter: this.contactSearchValue.toLowerCase(),
      });

      this.page++;
      this.clistContacts = [...this.clistContacts, ...handleClistContacts.contacts];
    },
    searchTable: debounce(async function () {
      this.contactConflicted = false;
      if (this.oldName != this.contactSearchValue) {
        this.page = 1;
      }
      const page = this.page;

      if (!this.contactSearchValue) {
        this.clistContacts = await contactList.getAllContactsQuery({
          offset: 0,
          limit: 20,
        });
        return;
      }

      let handleClistContacts = await contactList.getContactsByIdentifier({
        offset: (page - 1) * 20,
        limit: (page - 1) * 20 + 20,
        filter: this.contactSearchValue.toLowerCase(),
      });
      this.clistContacts = handleClistContacts.contacts;
      this.oldName = this.contactSearchValue.toLowerCase();
    }, 500),
    setValue(val) {
      this.contactSearchValue = val;
    },
    async fetchNextTemplatesPage() {
      try {
        this.nextPages.forEach(async (nextPage) => {
          this.nextPages = [];
          const { data: templates, paging } = await ChatAdapter.getTemplatesByConfigId({
            next: nextPage,
            configId: this.waba.id,
          });

          if (paging?.next) {
            this.nextPages.push(paging.next);
          }

          this.templates = this.templates.concat(templates.filter((template) => template.status === 'APPROVED'));
        });
      } catch (error) {}
    },
    replaceVariablesWithValue(text, variables, values) {
      if (!text || !variables || !values) return '';
      let replacedText = text;

      variables.forEach((variable, index) => {
        const variableValue = values[index];
        replacedText = variableValue ? replacedText.replace(`{{${variable}}}`, variableValue) : replacedText;
      });

      return replacedText;
    },
    handleSearchInput: debounce(async function (value) {
      this.templates = [];
      this.nextPages = [];
      this.resetTemplate();
      if (!value.trim()) {
        this.cancelToken.cancel();
        this.cancelToken = axios.CancelToken.source();
        await this.getTemplates();
        this.loadingSearchTemplate = false;
      } else {
        await this.searchTemplate();
      }
    }, 500),
    async searchTemplate() {
      try {
        const { data, paging } = await ChatAdapter.getTemplatesByConfigId({
          configId: this.waba.id,
          templateName: this.searchTemplateValue,
          cancelToken: this.cancelToken.token,
        });

        if (paging?.next) {
          this.nextPages.push(paging.next);
        }

        this.templates = this.templates
          .concat(data.filter((template) => template.status === 'APPROVED'))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.isEmptyTemplates = !this.templates.length;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingSearchTemplate = false;
      }
    },
    resetTemplate() {
      this.headerVariables = [];
      this.bodyVariables = [];
      this.headerVariablesValues = [];
      this.bodyVariablesValues = [];
      this.headerImageUrl = null;
      this.selectedTemplate = null;
      this.templateConfig = null;
      this.hasVariables = false;
    },
    async getTemplates() {
      try {
        const { data: templates, paging } = await ChatAdapter.getTemplatesByConfigId({
          configId: this.waba.id,
        });

        if (paging?.next) {
          this.nextPages.push(paging.next);
        }

        this.templates = this.templates
          .concat(templates.filter((template) => template.status === 'APPROVED'))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.isEmptyTemplates = !this.templates.length;
      } catch (error) {
        this.$toast.error(error);
      }
    },
    formatSnakeCaseName(name) {
      let uppercase = name[0].toUpperCase() + name.substring(1, name.length);
      return uppercase.split('_').join(' ');
    },
    async nextStep() {
      if (this.isProviderSelection) {
        if (!this.selectedProvider) return this.$toast.error('Selecione algum provedor para continuar');
        this.isProviderSelection = false;
        this.isFirstStep = true;
        return;
      }
      if (this.isFirstStep) {
        if (!this.campaignName) return this.$toast.error('De um nome para esta campanha antes de continuar');
        if (!this.waba.configurations?.phone) return this.$toast.error('Selecione um número antes de continuar');
        if (!this.selectedDate) return this.$toast.error('Selecione uma data para o término desta campanha');
        if (this.$moment(this.selectedDate).toISOString() < this.$moment(this.currentDate).toISOString())
          return this.$toast.error('Selecione uma data depois que a data atual');
        this.isFirstStep = false;
        this.isSecondStep = true;
        return;
      }
      if (this.isSecondStep) {
        if (!this.campaignResponsibleType) return this.$toast.error('Selecione o tipo de responsável desta campanha');
        if (!this.campaignResponsible?.id) return this.$toast.error('Selecione o responsável desta campanha');
        if (this.campaignResponsibleType == 'Grupo de atendimento') {
          try {
            const data = await ChatConfig.findOrCreateQueueRule({
              companyId: this.campaignResponsible.companyId,
              queueId: this.campaignResponsible.id,
              configId: this.waba.id,
            });
            this.campaignConversionRuleId = data.data.id;
            this.isSecondStep = false;
            this.isThirdStep = true;
            return;
          } catch (e) {
            this.$toast.error(e);
          }
          return;
        }
        this.campaignConversionRuleId = '';
        this.isSecondStep = false;
        this.isThirdStep = true;
        return;
      }
      if (this.isThirdStep) {
        if (this.mountedTemplateErrorMessage) return this.$toast.error(this.mountedTemplateErrorMessage);
        await this.createCampaign();
        return;
      }
    },
    previousStep(stepNumber) {
      if (this.isFourthStep)
        return this.$toast.info('Não é possível retornar a etapa após a campanha já ter sido criada');
      if (stepNumber == 1) {
        this.isFirstStep = true;
        this.isSecondStep = false;
        this.isThirdStep = false;
        this.isFourthStep = false;
        this.resetTemplate();
        this.templates = [];
        return;
      }
      if (stepNumber == 2) {
        this.isFirstStep = false;
        this.isSecondStep = true;
        this.isThirdStep = false;
        this.isFourthStep = false;
      }
    },
    selectProviderConfig(config) {
      if (config == this.waba) {
        this.waba = {};
        return;
      }
      this.waba = config;
    },
    getCurrentDay() {
      const now = new Date();
      this.selectedDate = now.setDate(now.getDate() + 1);
      this.currentDate = new Date();
    },
  },
  async created() {
    const { getConfigsByProviderId } = ChatConfig;
    try {
      const data = await getConfigsByProviderId(process.env.VUE_APP_WHATSAPP_PROVIDER_CONFIG_ID, { inbound: true });
      this.wabas = data.configurations;
      if (this.wabas.length == 1) this.waba = this.wabas[0];
    } catch (e) {
      this.$toast.error(e);
    }
    this.getCurrentDay();
  },
};
</script>

<style>
::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  opacity: 0%;
}
</style>
