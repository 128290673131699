<template lang="pug">
div.border.rounded-full.border-zinc-50.bg.overflow-hidden(class="dark:invert" @click="$emit('click')")
  img(v-show="imageLoaded", @error="imageLoaded = false", :src="src")
  img.h-full(
    v-show="!imageLoaded",
    src="@/assets/images/default_pfp.png"
  )
</template>

<script>
export default {
  data() {
    return {
      imageLoaded: true,
    };
  },
  props: {
    src: {
      type: String,
    },
    avatarId: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  methods: {
    imageLoadError() {
      this.imageLoaded = false;
    },
    isLoaded() {
      this.imageLoaded = true;
    },
  },
};
</script>

<style scoped>
.bg {
  background-image: linear-gradient(156.96deg, var(--brand-color) 9.13%, var(--brand-orange) 103.34%) !important;
}
</style>
