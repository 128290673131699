import AuthLayout from '../layout/AuthLayout.vue';
import DashboardLayout from '../layout/DashboardLayout.vue';

// GeneralViews
import NotFound from '../pages/NotFoundPage.vue';

// auth pages
import NewPassword from 'src/pages/Auth/NewPassword.vue';
import Register from 'src/pages/Auth/Register.vue';
import Login from 'src/pages/Auth/Login.vue';
import Recovery from 'src/pages/Auth/Recovery.vue';
import Product from '../pages/Auth/Product.vue';
import Confirmation from '../pages/Auth/Confirmation.vue';
// Admin pages
import Dashboard from 'src/pages/Dashboard';
import Queues from 'src/pages/Queues/index.vue';
import Contacts from 'src/pages/Contacts/Contacts.vue';
import AddContact from 'src/pages/Contacts/AddContact.vue';
import EditContact from 'src/pages/Contacts/EditContact.vue';
import ContactDetail from 'src/pages/Contacts/ContactDetail.vue';
import Organization from '@/pages/Organizations/Organization.vue';
import EditOrganization from '@/pages/Organizations/EditOrganization.vue';

import CallHistoricDetail from '@/pages/Callbacks/Callbacks.HistoricDetail.Call.vue';
import ChatHistoricDetail from '@/pages/Callbacks/Callbacks.HistoricDetail.Chat.vue';
import Preferences from 'src/pages/Preferences/Preferences.vue';
import Devices from 'src/pages/Preferences/Devices.vue';
import Integrations from 'src/pages/Preferences/Integrations.vue';
import ProfileSecurity from 'src/pages/ProfileSecurity/ProfileSecurity.vue';
import Callbacks from 'src/pages/Callbacks/Callbacks.vue';
import Cases from 'src/pages/Cases/Cases.vue';
import ContextArea from 'src/pages/ContextArea/ContextArea.vue';
import Campaigns from 'src/pages/Campaigns/index.vue';

import Notifications from 'src/pages/Notifications.vue';

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/context/:callId',
        name: 'ContextArea',
        component: ContextArea,
      },
      {
        path: '/profile',
        name: 'Profile',
        component: ProfileSecurity,
      },
    ],
  },
  {
    path: '/auth',
    component: Login,
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'register',
        name: 'Register',
        component: Register,
      },
      {
        path: 'recovery',
        name: 'Recovery',
        component: Recovery,
      },
    ],
  },
  {
    path: '',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Principal',
        component: Dashboard,
        meta: { conditionalRoute: true },
      },
      {
        path: '/queues',
        name: 'Queues',
        component: Queues,
        meta: { conditionalRoute: true },
      },
      {
        path: 'contacts',
        name: 'Contatos',
        component: Contacts,
        meta: { conditionalRoute: true },
      },
      {
        path: '/contacts/detail/:id',
        name: 'ContactDetail',
        component: ContactDetail,
        meta: { conditionalRoute: true },
      },
      {
        path: '/contacts/add',
        name: 'AddContact',
        component: AddContact,
        meta: { conditionalRoute: true },
      },
      {
        path: '/contacts/edit/:id',
        name: 'EditContact',
        component: EditContact,
        meta: { conditionalRoute: true },
      },
      {
        path: 'organizations',
        name: 'Organizations',
        component: Organization,
        meta: { conditionalRoute: true },
      },
      {
        path: '/organizations/edit/:id',
        name: 'EditOrganization',
        component: EditOrganization,
        meta: { conditionalRoute: true },
      },
      {
        path: 'preferences',
        name: 'Preferências',
        component: Preferences,
        redirect: 'preferences/devices',
      },
      {
        path: 'preferences/devices',
        name: 'Preferências',
        component: Devices,
      },
      {
        path: 'preferences/integrations',
        name: 'Preferências',
        component: Integrations,
      },
      {
        path: 'callback',
        name: 'Atividades',
        component: Callbacks,
        meta: { conditionalRoute: true },
      },
      {
        path: 'cases',
        name: 'Casos',
        component: Cases,
        meta: { conditionalRoute: true },
      },
      {
        path: 'callback/detail/:id',
        name: 'Detalhe Histórico',
        component: CallHistoricDetail,
        meta: { conditionalRoute: true },
      },
      {
        path: 'historic/detail/:id',
        name: 'Detalhe Histórico',
        component: CallHistoricDetail,
        meta: { conditionalRoute: true },
      },
      {
        path: 'historic/chat/:id',
        name: 'Detalhe Histórico',
        component: ChatHistoricDetail,
        meta: { conditionalRoute: true },
      },
      {
        path: '/chat',
        name: 'Chat',
        component: () => import('src/pages/Chat'),
        meta: { conditionalRoute: true },
      },
      {
        path: '/emails',
        name: 'Emails',
        component: () => import('src/pages/Emails'),
        meta: { conditionalRoute: true },
      },
      {
        path: '/campaigns',
        name: 'Campaigns',
        component: () => import('src/pages/Campaigns'),
        meta: { conditionalRoute: true },
      },
    ],
  },
  { path: '*', component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
