<template>
  <div v-if="user" class="call-received dark:invert" ref="inCallId">
    <a class="call-received-title" style="color: #adb5bd !important">Chamada finalizada</a>
    <div class="call-profile-img-box">
      <div class="colega-card-aligner">
        <div
          v-if="identityContact?.state"
          style="top: 0%"
          :class="'colega-status-disabled no-margin ' + translateCServiceStatus[identityContact?.state]"
        ></div>
      </div>
      <div class="call-profile-img-icon">
        <contact-avatar :identity-contact="identityContact" :failed-img="failedImg" @failedImg="failedImg = true">
        </contact-avatar>
      </div>
    </div>
    <div class="call-text-box">
      <a class="calling-dial-name white-text">{{ identityContact?.name || 'Desconhecido' }}</a>
    </div>
    <div class="align-center line-call-ended">
      <div v-if="identityContact?.tag" class="client-text colega-tag">
        {{ identityContact?.tag }}
      </div>
      <div v-else-if="identityContact?.state" class="client-text Colega colega-tag p-1">Colega</div>
      <a class="card-info-text" style="color: #adb5bd !important">{{ identityContact?.organization }}</a>
      <a class="card-info-text" style="color: #adb5bd !important">{{ identityContact.number }}</a>
      <div class="call-line-separator"></div>
      <div class="flex flex-col">
        <a class="card-info-text bold-text" style="color: #adb5bd !important">Duração</a>
        <a class="card-info-text" style="color: #adb5bd !important">{{ callDuration }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ContactAvatar from './ContactAvatar.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default {
  data() {
    return {
      failedImg: false,
      callDuration: '',
      translateCServiceStatus: {
        enabled: 'Disponível',
        disabled: 'NaoPerturbe',
        online: 'Disponível',
        auto: 'Disponível',
      },
    };
  },
  components: {
    ContactAvatar,
  },
  mixins: [UserOrContactMixin],
  async created() {
    let SECONDS = (new Date() - new Date(this.data.calldate)) / 1000;
    this.callDuration = new Date(SECONDS * 1000).toISOString().substr(11, 8);

    const fromId = this.data?.fromId || this.data?.from_id;
    const toId = this.data?.toId || this.data?.to_id;
    const isMineCall = fromId === this.user.id || this.data.from === this.user.endpoint;
    const callerId = isMineCall ? toId : fromId;
    const callerNumber = isMineCall ? this.data.to : this.data.from;

    await this.setContactOrOperator({
      id: callerId,
      number: callerNumber,
    });
  },
};
</script>

<style scoped>
.scroll-style {
  height: 56vh !important;
}
</style>

<style>
.input-line-aligner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
}
.keyboard-line-separator {
  background: #495057;
  height: 1px;
  width: 82.5%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.incall-transfer-input {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 24px;
  background: none;
  border: none;
  outline: none;
  color: #fff;
}
.icon-unknown {
  background-image: linear-gradient(156.96deg, var(--brand-color) 9.13%, var(--brand-orange) 103.34%) !important;
  font-size: 52px !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.transfer-icon-aligner {
  display: flex;
  padding-top: 30px;
  align-items: center;
  flex-direction: column;
}
.transfer-activity-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #868e96;
}
.in-call-icons-active {
  color: #212529;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.in-call-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #212529;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.noresult-text {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.transfer-search {
  background-color: #fff;
  border-radius: 4px;
  margin-top: 10px;
  width: 290px;
  max-height: 80%;
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.transfer-right-aligner {
  width: 55px;
}
.transfer-left-aligner {
  width: 255px;
  margin-bottom: 10px;
}
.cancel-transfer {
  font-family: ' Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #dc6058 !important;
}
.transfer-box {
  padding: 20px;
  margin-top: 30px;
  width: 320px;
  display: flex;
  justify-self: flex-start;
  flex-direction: column;
  align-items: center;
  height: 56%;
  background: #212529;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;
  border: none !important;
  outline: none !important;
}
.icon-container:focus .incall-icon {
  color: #212529;
  background: #fff;
}
.incall-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-weight: 100 !important;
  padding: 0px !important;
  font-size: 18px !important;
  border-radius: 50%;
  color: #fff;
  background: #212529;
}
</style>
