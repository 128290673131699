export default {
  namespaced: true,
  state: {
    isDialerOpen: false,
  },
  mutations: {
    setIsDialerOpen: function (state, payload) {
      state.isDialerOpen = payload;
    },
  },
  getters: {
    getIsDialerOpen: function (state) {
      return state.isDialerOpen;
    },
  },
};
