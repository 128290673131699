<template lang="pug">
.flex.flex-col
  p.font-semibold.text-gray-500.mr-auto.mb-2.font-plus(v-show="label") {{ label }}
  f-dropdown.w-full(placement="bottom-start", auto-size="min")
    .flex.justify-between.items-center.cursor-pointer.form-control.input.w-full
      p.text-zinc-600.text-base.font-normal.font-inter.truncate.flex.justify-between.gap-4.items-center {{ placeholder }}
        slot(name="additional")
      i.material-icons.notranslate.text-zinc-400.z-50 {{ icon }}
    template(#popper)
      .flex.flex-col.p-3.divide-y.max-h-80
        slot
</template>

<script>
import 'floating-vue/dist/style.css';
import { Dropdown } from 'floating-vue';
export default {
  components: {
    FDropdown: Dropdown,
  },
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Selecione uma ou mais opções',
    },
    icon: {
      type: String,
      default: 'keyboard_arrow_down',
    },
  },
};
</script>
