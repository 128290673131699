<template lang="pug">
section.font-inter
  slot(name="title")
  
  section(class="grid grid-cols-5 text-center text-gray-500 child:font-bold")
    p.text-left.leading-4.mb-2.text-xs(class="lg:text-sm") Etapa da campanha
    p.leading-4.col-span-2.mb-2.text-xs(class="lg:text-sm") Quantidade de contatos
    p.leading-4.mb-2.text-xs(class="lg:text-sm") Conversão na próxima etapa
    p.leading-4.mb-2.text-xs(class="lg:text-sm") Conversão cumulativa

    span(class="h-10 bg-gray-200 rounded-l")
      p.pl-2.leading-10.font-bold.text-left.text-gray-800 Criado
    span(class="relative w-full col-span-2 h-10")
      span(class="absolute left-0 top-0 w-full h-full bg-gray-200")
      div(class="w-full bg-[#6d3595] rounded h-full" :class="{ 'w-[2%] absolute right-0': queued === 0, 'relative': queued > 0 }")
        p.pl-2.leading-10.font-bold.text-left.text-white.h-full(v-if="queued > 0") {{ queued }}
        p.pl-2.leading-10.font-bold.text-left.text-gray-800.h-full.absolute.right-6(v-else) {{ queued }}
    p.leading-10.relative.z-10.mx-4.my-1.text-gray-800 {{ queuedNextStepConversion }}%
      span(class="absolute left-0 h-[85%] w-[51%] bg-gray-200 skew-y-[11deg] z-[-1]")
      span(class="absolute right-0 h-[85%] w-1/2 bg-gray-200 skew-y-[-11deg] z-[-1]")
    p.leading-10.relative.z-10.mx-4.my-1.text-gray-800 {{ queuedCumulativeConversion }}%
      span(class="absolute left-0 h-[85%] w-[51%] bg-gray-200 skew-y-[11deg] z-[-1]")
      span(class="absolute right-0 h-[85%] w-1/2 bg-gray-200 skew-y-[-11deg] z-[-1]")

    span(class="h-10 bg-gray-200 rounded-l")
      p.pl-2.leading-10.font-bold.text-left.text-gray-800 Enviado
    span(class="relative w-full col-span-2 h-10")
      span(class="absolute left-0 top-0 w-full h-full bg-gray-200")
      div(:style="{ width: queuedNextStepConversion + '%' }" class="max-w-[100%] min-w-[2%] h-full ml-auto bg-[#6d3595] relative rounded")
        p.pl-2.leading-10.font-bold.text-left.text-white.h-full(v-if="queuedCumulativeConversion >= 10") {{ sent }}
        p.pl-2.leading-10.font-bold.text-left.text-gray-800.h-full.absolute.right-6(v-else) {{ sent }}
    p.leading-10.relative.z-10.mx-4.my-1.text-gray-800 {{ sentNextStepConversion }}%
      span(class="absolute left-0 h-[85%] w-[51%] bg-gray-200 skew-y-[11deg] z-[-1]")
      span(class="absolute right-0 h-[85%] w-1/2 bg-gray-200 skew-y-[-11deg] z-[-1]")
    p.leading-10.relative.z-10.mx-4.my-1.text-gray-800 {{ sentCumulativeConversion }}%
      span(class="absolute left-0 h-[85%] w-[51%] bg-gray-200 skew-y-[11deg] z-[-1]")
      span(class="absolute right-0 h-[85%] w-1/2 bg-gray-200 skew-y-[-11deg] z-[-1]")

    span(class="h-10 bg-gray-200 rounded-l")
      p.pl-2.leading-10.font-bold.text-left.text-gray-800 Convertido
    span(class="relative w-full col-span-2 h-10")
      span(class="absolute left-0 top-0 w-full h-full bg-gray-200")
      div(:style="{ width: sentCumulativeConversion + '%' }" class="max-w-[100%] min-w-[2%] h-full ml-auto bg-[#6d3595] relative rounded")
        p.pl-2.leading-10.font-bold.text-left.text-white.h-full(v-if="sentCumulativeConversion >= 10") {{ converted }}
        p.pl-2.leading-10.font-bold.text-left.text-gray-800.h-full.absolute.right-6(v-else) {{ converted }}
</template>

<script>
export default {
  computed: {
    queuedNextStepConversion() {
      return `${Math.round((this.sent / this.queued) * 100) || 0}`;
    },
    queuedCumulativeConversion() {
      return `${Math.round((this.sent / this.queued) * 100) || 0}`;
    },
    sentNextStepConversion() {
      return `${Math.round((this.converted / this.sent) * 100) || 0}`;
    },
    sentCumulativeConversion() {
      return `${Math.round((this.converted / this.queued) * 100) || 0}`;
    },
  },
  props: {
    queued: {
      type: Number,
      required: true,
    },
    sent: {
      type: Number,
      required: true,
    },
    converted: {
      type: Number,
      required: true,
    },
  },
};
</script>
