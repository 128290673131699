<template lang="pug">
.flex.flex-col.relative(
  id="dialer-container"
)
  .flex.rounded-xl.border.items-center.justify-center.w-11.h-11.transition-all.cursor-pointer(
    class="hover:bg-gray-100 active:scale-90",
  )
    .material-icons.text-gray-500(style="font-size: 18px") phone
  .flex.absolute.top-12.right-0
    dialer-layout
      template(#header)
        dialer-header Disponível
      template(#body)
        .flex.flex-col.gap-2
          .flex.flex-col.items-start
            p.text-white.font-plus Iniciar chamada
            p.font-plus.text-gray-300.text-xs Inicie uma chamada com algum dos seus colegas, contatos ou números de telefone
          
          icon-input(
            label="Colegas, contatos e números",
            dark
            id="dialer-search"
          )

          .flex.flex-col.w-full
            .flex.w-full.justify-between.cursor-pointer
              p.font-plus.text-gray-300.text-xs CONTATOS
              .material-icons.notranslate keyboard_arrow_up
            .flex.flex-col.w-full.overflow-y-scroll.justify-start.gap-2.max-h-36(
              id="dialer-contacts"
            )
              dialer-contact-card(
                :name="'Contato'",
                :endpoint="'1234567890'"
              )
                template(#avatar)
                  avatar(
                    :src="'https://s3-alpha-sig.figma.com/img/6c1f/e88a/3b9e8dfddf4a065581b04df49638ca9c?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ffKmMWaQxZr2j4k07JUEsL0VBGC3eaPd~Cw0tgkPaltq5QpEoT9MP8cwNz0erF5AqBIRMpcue~eFRVfzR8T5WlPGrvnEPl8kYGg01w~GX1ON5p7m7b0F4WiAfgsyXMU9JQA4ZwGeMoY5buWAs4DUxDCnVyQFWuY41hfckZav4N20w3Wg21D3RcUuGjcSpOA7UY2TDVS5YQuxyq0urhiXjMCkuuW2iiC2eU9TLnxuM~qQPBiE1pTF2gaYaitC6QWaYSJ~a7O7vQjZkaKqJWeG2Yfl9rMJaQveeqslFGC76K9oaHHETzMuvUgsX5zUyLUcYbIPzHoUlsamnGta30wNNg__'",
                    :avatarId="'1234567890'",
                  )

          .flex.flex-col.w-full

            .flex.w-full.justify-between.cursor-pointer
              p.font-plus.text-gray-300.text-xs COLEGAS
              .material-icons.notranslate keyboard_arrow_up
            .flex.flex-col.w-full.overflow-y-scroll.justify-start.gap-2.max-h-36(
              id="dialer-mates"
            )
              dialer-contact-card(
                :name="'Colega'",
                :endpoint="'1234567890'"
              )
                template(#avatar)
                  avatar(
                    :src="'https://s3-alpha-sig.figma.com/img/6c1f/e88a/3b9e8dfddf4a065581b04df49638ca9c?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ffKmMWaQxZr2j4k07JUEsL0VBGC3eaPd~Cw0tgkPaltq5QpEoT9MP8cwNz0erF5AqBIRMpcue~eFRVfzR8T5WlPGrvnEPl8kYGg01w~GX1ON5p7m7b0F4WiAfgsyXMU9JQA4ZwGeMoY5buWAs4DUxDCnVyQFWuY41hfckZav4N20w3Wg21D3RcUuGjcSpOA7UY2TDVS5YQuxyq0urhiXjMCkuuW2iiC2eU9TLnxuM~qQPBiE1pTF2gaYaitC6QWaYSJ~a7O7vQjZkaKqJWeG2Yfl9rMJaQveeqslFGC76K9oaHHETzMuvUgsX5zUyLUcYbIPzHoUlsamnGta30wNNg__'",
                    :avatarId="'1234567890'",
                  )
</template>
<script>
import DialerContactCard from '@/components/Dialer/dialer-contact-card.vue';
import DialerLayout from '@/components/Dialer/dialer-layout.vue';
import DialerOption from '@/components/Dialer/dialer-option.vue';
import DialerHeader from '@/components/Dialer/dialer-header.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import Avatar from '@/components/Avatars/avatar.vue';
import { Dropdown, Tooltip } from 'floating-vue';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

export default {
  components: {
    FDropdown: Dropdown,
    FTooltip: Tooltip,
    DialerContactCard,
    DialerLayout,
    DialerOption,
    DialerHeader,
    IconInput,
    Avatar,
  },
  data() {
    return {
      driverObj: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initTour();
      this.startTour();
    });
  },
  methods: {
    initTour() {
      this.driverObj = driver({
        showProgress: true,
        animate: true,
        onDestroyStarted: () => {
          this.driverObj.destroy();
          localStorage.setItem('dialerTourCompleted', 'true');
          this.$emit('tour-completed');
        },
        steps: [
          {
            element: '#dialer-container',
            popover: {
              title: 'Nova interface de chamada',
              description: 'Esta é a nova interface de chamada onde você pode gerenciar suas ligações.',
              position: 'bottom',
              nextBtnText: 'Próximo',
              prevBtnText: 'Anterior',
              doneBtnText: 'Concluir',
            },
          },
          {
            element: '#dialer-search',
            popover: {
              title: 'Barra de pesquisa',
              description: 'Use esta barra para pesquisar colegas, contatos e números.',
              position: 'bottom',
              nextBtnText: 'Próximo',
              prevBtnText: 'Anterior',
              doneBtnText: 'Concluir',
            },
          },
          {
            element: '#dialer-contacts',
            popover: {
              title: 'Lista de contatos',
              description: 'Use esta lista para ver seus contatos.',
              position: 'bottom',
              nextBtnText: 'Próximo',
              prevBtnText: 'Anterior',
              doneBtnText: 'Concluir',
            },
          },
          {
            element: '#dialer-mates',
            popover: {
              title: 'Lista de colegas',
              description: 'Use esta lista para ver seus colegas.',
              position: 'bottom',
              nextBtnText: 'Próximo',
              prevBtnText: 'Anterior',
              doneBtnText: 'Concluir',
            },
          },
        ],
      });
    },
    startTour() {
      if (this.driverObj) {
        try {
          this.driverObj.drive();
        } catch (error) {
          console.error('Error starting tour:', error);
        }
      }
    },
  },
};
</script>
<style>
.driver-popover-next-btn,
.driver-popover-prev-btn {
  text-shadow: none !important;
  @apply bg-brand rounded-md text-white shadow-none hover:opacity-50 font-plus outline-none border-none transition-all dark:invert !important;
}

.driver-popover-description,
.driver-popover-title {
  @apply font-plus !important;
}
</style>
