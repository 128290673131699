<template lang="pug">
.flex.flex-col.justify-center.items-center.h-full(
  v-if="!activeCampaignId",
  class="w-3/4"
)
  img.mb-12(src="~@/assets/images/campaignIllustration.svg", alt="altText")
  p.font-extrabold.text-3xl.text-gray-600.mb-12.text-center Utilize campanhas e alcance mais clientes de uma só vez!
  p.text-gray-400.text-center(class="w-2/3") Impulsione sua comunicação com campanhas diretas e eficazes. Envie mensagens padronizadas para vários contatos de forma rápida e fácil.
.flex.flex-col.h-full.flex-1.p-4.items-start.bg-zinc-50.border-l.overflow-auto.gap-4.justify-start(
  v-else-if="loading"
)
  loading-ring
.flex.flex-col.h-full.w-full.p-4.items-start.bg-zinc-50.border-l.overflow-hidden.gap-4.justify-start(
  v-else
)
  .w-full.flex.flex-col.items-start.gap-4
    .flex.w-full.justify-between.border-b-2.pb-2
      .flex.gap-2.justify-start.w-full
        .flex.gap-2
          .font-titillium.text-zinc-700.text-xl.font-extrabold {{ campaign.name }}
          .flex.min-w-fit.p-2.border.border-blue-400.border-dashed.rounded-md.gap-2.cursor-help(v-if="campaign.status != 'finished' && campaign.expiresAt" v-tooltip="'Data de validade da campanha'")
            p.font-plus.text-blue-400.font-medium {{ formatDateToBrasilType(campaign.expiresAt) }}
            .material-icons.notranslate.text-blue-400 calendar_month
        .flex.p-2.border.border-red-500.rounded-md.justify-center.gap-2.border-dashed(v-if="campaign.status == 'finished'")
          p.text-red-500.font-plus.font-semibold Campanha finalizada
      .flex.min-w-fit.gap-2(v-if="campaign.status != 'finished' && (user.profile == 'p_manager' || user.profile == 'p_admin')")
        v-button.min-w-fit.text-sm(danger @click="showEndCampaignModal = true") Encerrar campanha
      modal(v-if="showEndCampaignModal" @click="showEndCampaignModal = false")
        modal-body
          template(v-slot:header)
            .flex
              v-heading Encerrar campanha
              .material-icons.notranslate.ml-auto.cursor-pointer.text-gray-400(@click="showEndCampaignModal = false") close
          template(v-slot:body)
            p Tem certeza que deseja encerrar esta campanha?
          template(v-slot:footer)
            .flex.gap-2
              v-button-secondary(@click="showEndCampaignModal = false") Cancelar
              v-button(@click="endCampaign") Encerrar campanha
  .flex.w-full.justify-end.divide-x.gap-1

    .flex.cursor-pointer.p-1.rounded(
      @click="shownOption = 'all'"
      title="Ver tudo"
      class="hover:bg-brand-100 hover:shadow-md active:scale-90 transition-al"
      :class="{'bg-brand-500 pointer-events-none' : shownOption == 'all'}"
    )
      .material-icons.notranslate.text-gray-600(:class="{'!text-white' : shownOption == 'all'}") apps


    .flex.cursor-pointer.p-1.rounded(
      @click="shownOption = 'graph'"
      title="Mostrar apenas gráfico"
      class="transition-all hover:bg-brand-100 hover:shadow-md active:scale-90"
      :class="{'bg-brand-500 pointer-events-none' : shownOption == 'graph'}"
    )
      .material-icons.notranslate.text-gray-600(:class="{'!text-white' : shownOption == 'graph'}") analytics

    .flex.cursor-pointer.p-1.rounded(
      @click="shownOption = 'contacts'"
      title="Mostrar apenas contatos"
      class="transition-all hover:bg-brand-100 hover:shadow-md active:scale-90"
      :class="{'bg-brand-500 pointer-events-none' : shownOption == 'contacts'}"
    )
      .material-icons.notranslate.text-gray-600(:class="{'!text-white' : shownOption == 'contacts'}") groups

  .flex.w-full.gap-4(
    v-if="shownOption == 'all' || shownOption == 'graph'"
    :class="{'h-full' : shownOption == 'graph'}"
  )
    .flex.flex-col.h-full.gap-2(class="w-[40%]")
      .flex-1.border.rounded-md.bg-white.justify-between.items-center.flex.w-full.px-4.py-2
        .flex.gap-2
          .flex.bg-blue-200.w-12.h-12.p-5.rounded-full
            .material-icons.notranslate.text-blue-600 cloud_upload
          .flex.flex-col.items-start
              p.font-plus.font-semibold Contatos
              p.font-plus.text-xl.font-bold {{ campaign.queuedCampaignAttempts }}
        app-tooltip(info) Número de tentativas de contatos que foram feitas durante essa campanha
      .flex-1.border.rounded-md.bg-white.justify-between.items-center.flex.w-full.px-4.py-2
        .flex.gap-2
          .flex.bg-amber-200.w-12.h-12.p-5.rounded-full
            .material-icons.notranslate.text-amber-600 mail
          .flex.flex-col.items-start
              p.font-plus.font-semibold Entregues
              p.font-plus.text-xl.font-bold {{ campaign.deliveredCampaignAttempts }}
        app-tooltip(info) Esse status significa que a mensagem já foi entregue ao canal responsável por fazer o envio da mensagem

      .flex-1.border.rounded-md.bg-white.justify-between.items-center.flex.w-full.px-4.py-2
        .flex.gap-2
          .flex.bg-emerald-200.w-12.h-12.p-5.rounded-full
            .material-icons.notranslate.text-emerald-600 sync
          .flex.flex-col.items-start
              p.font-plus.font-semibold Convertidos
              p.font-plus.text-xl.font-bold {{ campaign.convertedCampaignAttempts }}
        app-tooltip(info) A tentativa de contato sera contabilizada como "convertida" caso o destinatário responda a mensagem recebida
      .flex-1.border.rounded-md.bg-white.justify-between.items-center.flex.w-full.px-4.py-2
        .flex.gap-2
          .flex.bg-red-200.w-12.h-12.p-5.rounded-full
            .material-icons.notranslate.text-red-600 error
          .flex.flex-col.items-start
              p.font-plus.font-semibold Rejeitados
              p.font-plus.text-xl.font-bold {{ campaign.failedCampaignAttempts }}
        app-tooltip(info) Contabilizado quando não é possivel entrar em contato com o destino dessa campanha por algum motivo
    .flex.border.border-dashed.h-full(
      class="w-[60%] px-4"
    )
      funnel-chart(
        :queued="campaign.queuedCampaignAttempts"
        :sent="campaign.deliveredCampaignAttempts"
        :converted="campaign.convertedCampaignAttempts"
      )
        template(v-slot:title)
          h3.font-bold.text-gray-600.text-lg.text-center.pb-4 Status das mensagens enviadas para os contatos

      .material-icons.notranslate.text-gray-400.cursor-pointer(class="transition-all active:scale-90" @click="showGraphHelpModal = true" v-tooltip="'Clique para ver como funciona o gráfico'") info
      chart-example-modal(v-if="showGraphHelpModal" @close="showGraphHelpModal = false")
  .flex.w-full.gap-2.h-11(v-if="shownOption == 'all' || shownOption == 'contacts'")
    v-input.h-full(
      label="Nome do contato"
      v-model="searchCampaignContact"
    )
    v-dropdown.text-sm.min-w-fit.h-full(useAllHeight, class="transition-all active:scale-90", :placeholder="contactDispatchStatusFilter ? getStatusLabel(contactDispatchStatusFilter) : 'Filtro'")
      ul.divide-y(v-close-popper.all)
        dropdown-item(
          class="transition-all active:scale-90"
          label="Remover filtro",
          icon="\e5cd"
          @click="contactDispatchStatusFilter = null"
        )
        dropdown-item(
          class="transition-all active:scale-90"
          label="Enviando",
          icon="\e2c3"
          @click="contactDispatchStatusFilter = 'queued'"
        )
        dropdown-item(
          class="transition-all active:scale-90"
          label="Entregue",
          icon="\e158"
          @click="contactDispatchStatusFilter = 'delivered'"
        )
        dropdown-item(
          class="transition-all active:scale-90"
          label="Convertido",
          icon="\e627"
          @click="contactDispatchStatusFilter = 'converted'"
        )
        dropdown-item(
          class="transition-all active:scale-90"
          label="Rejeitado",
          icon="e000"
          @click="contactDispatchStatusFilter = 'failed'"
        )
    .material-icons.notranslate.p-2.text-white.bg-brand-500.rounded-md.cursor-pointer(class="transition-all active:scale-90" v-tooltip="'Adicionar contatos'" @click="showAddContactsToCampaignModal = true" v-if="campaign.status != 'finished' && (user.profile == 'p_manager' || user.profile == 'p_admin')") add

    modal(
      v-if="showAddContactsToCampaignModal"
      @click="showAddContactsToCampaignModal = false"
    )
      modal-body(class="w-[37rem]")
        template(v-slot:header)
          .flex
            v-heading Adicionar contatos
            .material-icons.notranslate.ml-auto.cursor-pointer.text-gray-400(@click="showAddContactsToCampaignModal = false") close
        template(v-slot:body)
          .flex.flex-col.p-2.items-start.gap-4
            .flex.p-2.items-center.border.border-red-500.rounded-lg.w-full.justify-center.gap-2
              .material-icons.notranslate.text-red-500 warning
              p.text-red-500.font-semibold Esta operação poderá gerar custos adicionais de envio na META
            contact-phone-multi-selection-dropdown(
              description="Você só pode adicionar no máximo 100 contatos por vez."
              :custom-payload="contactsPayload"
              :selection-limit="100"
              @selected-contacts-list="handleSelectedContacts"
            )
              template(#search="{search}")
                .flex
                  v-input(
                    @input="search"
                    :label="'Nome do usuário'"
                  )
              template(#items="{contact, selected}")
                .flex
                  contact-phone-row(
                    :data="contact"
                    :selected="selected"
                  )
                  v-tooltip(
                    @apply-show="getContactCampaignsInfo(contact.contact)"
                    v-if="contact?.contact?.campaigns[0]?.campaignId"
                  )
                    .material-icons.px-2.cursor-help campaign
                    template(#popper)
                      p Este usuário já está em uma ou mais campanhas
                      p(v-if="loadingSelectedContactCampaignNames") Carregando...
                      div(v-else, v-html="selectedContactCampaignNames")
              template(#options="{selectAll, deselectAll}")
                .flex.flex-col.gap-2.w-full
                  .flex.gap-2.justify-start.py-2.border-y.w-full
                    input(type="checkbox", v-model="includeContactsInCampaign")
                    p.text-xs.text-gray-500 Incluir contatos que já estão em uma campanha
                  .flex.gap-2
                    v-button-secondary.w-full(
                      small
                      @click="deselectAll"
                    ) Desmarcar todos
                    v-button-secondary.w-full(
                      small
                      @click="selectAll"
                    ) Selecionar todos
        template(v-slot:footer)
          .flex.gap-2
            v-button-secondary(@click="showAddContactsToCampaignModal = false") Cancelar
            v-button(@click="addContactsToCampaign") Adicionar contatos

    .material-icons.notranslate.p-2.text-white.bg-brand-300.rounded-md.cursor-pointer(class="transition-all active:scale-90" v-tooltip="'Recarregar contatos'" @click="getAllCampaignInfos({})") refresh
  .flex.flex-1.w-full.overflow-auto.items-start(v-if="campaignContacts.length > 0 && !loadingContacts" v-show="shownOption == 'all' || shownOption == 'contacts'")
    v-table
      template(v-slot:header)
        v-table-head
          td.font-inter.font-semibold Contato
          td.font-inter.font-semibold Status
          td.flex.justify-start.gap-1
            p.font-inter.font-semibold Canal da conversa
            app-tooltip Canal da conversa que foi iniciada pela campanha
      template(v-slot:body)
        v-table-row(
          v-for="contact in campaignContacts"
          :key="contact.id"
        )
          td.flex.gap-4.justify-start
            avatar2.w-10.h-10.ml-2(
              :src="`${requestLinks.clist}/uploads/${contact.contactId}.jpg`"
              :avatarId="contact.id"
            )
            .flex.flex-col.items-start
              p.font-semibold.font-inter {{ contact.contactName }}
              p.font-inter.text-xs.text-zinc-400 {{ contact.contactEndpoint }}
          td
            .flex.p-1.w-fit.px-4.gap-2.rounded-full(:class='[getStatusColor(contact.dispatchStatus)]' v-tooltip="contact.dispatchStatusMessage")
              loading-ring(small color="fill-blue-600" backgroundColor="text-white" v-if="contact.dispatchStatus == 'queued'")
              .material-icons.notranslate.text-base(v-else :class='[getStatusColor(contact.dispatchStatus)]') {{ getStatusIcon(contact.dispatchStatus) }}
              p.font-plus.font-bold {{ getStatusLabel(contact.dispatchStatus) }}
          td
            v-button-secondary(@click="openCommunicationChannel(contact.communicationChannelId)" v-if="contact.communicationChannelId") Ver conversa
            p.text-gray-400(v-else) Canal não gerado
  pagination.ml-auto(
    v-if="campaignContacts.length > 0 && !loadingContacts && (shownOption == 'all' || shownOption == 'contacts')"
    :perPage="limit",
    :showLimitOptions="false"
    :totalItems="totalCampaignContacts"
    :currentPage="currentPage",
    :hasMorePages="hasMorePages",
    @pagechanged="onPageChange"
    @limit="setLimit",
  )
  p(v-if="campaignContacts.length == 0 && (shownOption == 'all' || shownOption == 'contacts')").w-full.p-4.text-center.font-inter.text-sm.text-gray-400.font-medium Nenhum contato encontrado
</template>

<script>
import ContactPhoneMultiSelectionDropdown from '@/components/Dropdown/contact-phone-multi-selection-dropdown.vue';
import ContactPhoneRow from '@/components/TablesRows/contact-phone-row.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import ModalBodyNoFooter from '@/components/Modals/ModalBodyNoFooter.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import vTableHead from '@/components/Tables/v-table-head.vue';
import vDropdown from '@/components/Dropdown/v-dropdown.vue';
import vTableRow from '@/components/Tables/v-table-row.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VButton from '@/components/Buttons/v-button.vue';
import ChartExampleModal from './ChartExampleModal.vue';
import avatar2 from '@/components/Avatars/avatar2.vue';
import VInput from '@/components/Inputs/IconInput.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import VHeading from '@/components/Text/v-heading.vue';
import vTable from '@/components/Tables/v-table.vue';
import Pagination from '@/components/Pagination.vue';
import { Tooltip as VTooltip } from 'floating-vue';
import Modal from '@/components/Modals/Modal.vue';
import Campaign from '@/services/campaigns';
import clist from '@/services/contact-list';
import FunnelChart from './FunnelChart.vue';
import eventBus from '@/main-event-bus.js';
import chatService from '@/services/chat';
import { debounce } from '@/helpers';
import chat from '@/services/chat';

export default {
  components: {
    ContactPhoneMultiSelectionDropdown,
    ChartExampleModal,
    ModalBodyNoFooter,
    VButtonSecondary,
    ContactPhoneRow,
    DropdownItem,
    LoadingRing,
    FunnelChart,
    vTableHead,
    Pagination,
    vTableRow,
    ModalBody,
    vDropdown,
    VTooltip,
    VHeading,
    avatar2,
    VButton,
    vTable,
    VInput,
    Modal,
  },
  data() {
    return {
      selectedContactCampaignNames: '',
      contactDispatchStatusFilter: '',
      searchCampaignContact: '',
      shownOption: 'all',
      totalCampaignContacts: 0,
      currentPage: 1,
      limit: 10,
      loadingSelectedContactCampaignNames: false,
      showAddContactsToCampaignModal: false,
      includeContactsInCampaign: false,
      showEndCampaignModal: false,
      showGraphHelpModal: false,
      loadingContacts: true,
      loading: true,
      interval: null,
      chart: null,
      campaignContacts: [],
      selectedContacts: {},
      campaign: {},
    };
  },
  computed: {
    contactsPayload() {
      const payload = {
        associations: ['campaigns'],
        campaigns: { isActive: true },
        excluding: ['campaigns'],
      };
      if (!this.includeContactsInCampaign) return payload;
      delete payload.excluding;
      return payload;
    },
    containerView() {
      return this.$store.getters['chatView/getContainerView'];
    },
    activeCampaignId() {
      return this.$store.getters['campaign/getActiveCampaignId'];
    },
    handleUpdateCampaigns() {
      return this.$store.getters['campaign/getHandleCampaignUpdate'];
    },
    async currentRoute() {
      if (this.activeCampaignId) {
        await this.getAllCampaignInfos({});
      }
      return this.$router.currentRoute;
    },
    hasMorePages() {
      const totalPages = Math.ceil(this.totalCampaignContacts / this.limit);
      return this.currentPage < totalPages;
    },
  },
  watch: {
    async activeCampaignId(val) {
      if (!val) return;
      await this.getAllCampaignInfos({});
    },
    async handleUpdateCampaigns() {
      await this.getAllCampaignInfos({});
    },
    async contactDispatchStatusFilter() {
      await this.getCampaignContacts({});
    },
    async searchCampaignContact() {
      this.searchContacts();
    },

    //Pagination
    async limit() {
      await this.getCampaignContacts({});
    },
  },
  methods: {
    async getContactCampaignsInfo(contact) {
      const { getCampaignById } = Campaign;
      if (this.loadingSelectedContactCampaignNames) return;
      this.loadingSelectedContactCampaignNames = true;
      const campaigns = contact.campaigns;
      let campaignNames = '';
      for (const campaign of campaigns) {
        try {
          const data = await getCampaignById(campaign.campaignId);
          campaignNames += `${data.name};<br>`;
        } catch (error) {
          campaignNames += `Campanha desconhecida;<br>`;
        }
      }
      this.selectedContactCampaignNames = campaignNames;
      this.loadingSelectedContactCampaignNames = false;
    },
    handleSelectedContacts(data) {
      this.selectedContacts = data;
    },
    async openCommunicationChannel(channelId) {
      const roomsIds = this.$store.getters['chat/rooms'].map((room) => room.id);

      const room = await chat.findById(channelId, {
        companyId: this.user.company_id,
        includeMembers: true,
        includeMessages: true,
        limitMessages: 15,
        orderMessages: 'desc',
      });

      if (!roomsIds.includes(+channelId)) this.$store.commit('chat/addRoom', room);

      this.$router.push({ name: 'Chat' });
      this.$store.commit('chat/setActiveRoom', { roomId: channelId, room: null });
    },
    searchContacts: debounce(async function () {
      await this.getCampaignContacts({});
    }, 500),
    formatDateToBrasilType(date) {
      const removedHourFromDate = date.split('T');
      const splittedDate = removedHourFromDate[0].split('-');
      const formattedDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
      return formattedDate;
    },
    async addContactsToCampaign() {
      if (!Object.keys(this.selectedContacts).length)
        return this.$toast.error('Selecione algum usuário para adicionar a esta campanha');
      const { addContactsToCampaign } = Campaign;
      try {
        const contactsList = [];
        for (const contact in this.selectedContacts) {
          contactsList.push({
            contactId: contact,
            campaignId: this.activeCampaignId,
            contactName: this.selectedContacts[contact].name,
            contactEndpoint: this.selectedContacts[contact].number,
            contactEndpointId: this.selectedContacts[contact].numberId.toString(),
          });
        }
        await addContactsToCampaign(contactsList);
        this.$toast.success('Contatos adicionados com sucesso!');
        this.showAddContactsToCampaignModal = false;
        await this.reloadCampaign();
      } catch (e) {
        console.error(e);
        this.$toast.error('Não foi possível adicionar os contatos a esta campanha');
      }
    },
    async getCampaign({ reload = true }) {
      this.loading = reload;
      const { getCampaignById } = Campaign;
      try {
        const data = await getCampaignById(this.activeCampaignId);
        this.campaign = data;
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
    async getCampaignContacts({ reload = true }) {
      if (!this.activeCampaignId) return;
      this.loadingContacts = reload;
      const { getCampaignContacts } = Campaign;
      try {
        const payload = {
          campaignId: this.activeCampaignId,
          $limit: this.limit,
          $skip: (this.currentPage - 1) * 10,
          $sort: { contactName: 1 },
        };
        if (this.contactDispatchStatusFilter) payload.dispatchStatus = this.contactDispatchStatusFilter;
        if (this.searchCampaignContact)
          payload.$or = [
            {
              contactName: {
                $ilike: `%${this.searchCampaignContact}%`,
              },
            },
            {
              contactEndpoint: {
                $ilike: `%${this.searchCampaignContact}%`,
              },
            },
          ];
        const campaignContacts = await getCampaignContacts(payload);
        this.totalCampaignContacts = campaignContacts.total;
        this.campaignContacts = campaignContacts.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingContacts = false;
      }
    },
    async endCampaign() {
      const { setCampaignStatus } = Campaign;
      try {
        await setCampaignStatus(this.activeCampaignId, 'finished');
        this.showEndCampaignModal = false;
        this.$store.commit('campaign/setActiveCampaignId', null);
        const currentValue = this.$store.getters['campaign/getHandleCampaignUpdate'];
        this.$store.commit('campaign/setHandleCampaignsUpdate', currentValue + 1);
        this.$toast.success('Campanha finalizada com sucesso!');
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async getAllCampaignInfos({ reload = true }) {
      if (!this.activeCampaignId || this.showAddContactsToCampaignModal) return this.reloadCampaign();
      await this.getCampaign({ reload: reload });
      await this.getCampaignContacts({ reload: reload });
      await this.reloadCampaign();
    },
    async reloadCampaign() {
      this.currentPage = 1;
      if (this.interval) clearInterval(this.interval);
      this.interval = setTimeout(async () => {
        await this.getAllCampaignInfos({ reload: false });
      }, 1000 * 15);
    },
    getStatusIcon(statusName) {
      switch (statusName) {
        case 'delivered':
          return 'mail';
        case 'queued':
          return 'cloud_upload';
        case 'converted':
          return 'sync';
        case 'failed':
          return 'error';
      }
    },
    getStatusColor(statusName) {
      switch (statusName) {
        case 'delivered':
          return 'bg-amber-100 text-amber-700';
        case 'queued':
          return 'bg-blue-100 text-blue-700';
        case 'converted':
          return 'bg-emerald-100 text-emerald-700';
        case 'failed':
          return 'bg-red-100 text-red-700';
      }
    },
    getStatusLabel(statusName) {
      switch (statusName) {
        case 'delivered':
          return 'Entregue';
        case 'queued':
          return 'Enviando';
        case 'converted':
          return 'Convertido';
        case 'failed':
          return 'Rejeitado';
      }
    },

    //Pagination
    async onPageChange(page) {
      this.currentPage = page;
      await this.getCampaignContacts({});
    },
    setLimit(value) {
      this.limit = value;
      this.resetPagination();
    },
    resetPagination() {
      this.currentPage = 1;
    },
  },
};
</script>
