<template lang="pug">
dialer-layout
  template(#header)
    dialer-header Disponível
  template(#body)
    .flex.flex-col.gap-2
      .flex.flex-col.items-start
        p.text-white.font-plus Iniciar chamada
        p.font-plus.text-gray-300.text-xs Inicie uma chamada com algum dos seus colegas, contatos ou números de telefone
      
      .flex.flex-col(v-if="loadingCalling")
        loading-ring

      .flex.flex-col.w-full.gap-2(v-else)
        icon-input(
          label="Colegas, contatos e números",
          v-model="searchInput",
          dark,
          @keyup-enter="handleMakeCall(searchInput)"
        )

        dialer-contact-card(
          v-if="isOnlyNumbers(searchInput)",
          name="Ligar Para"
          :endpoint="searchInput"
          @make-call="handleMakeCall(searchInput)"
        )

        .flex.flex-col.w-full
          .flex.w-full.justify-between.cursor-pointer(
            @click="isContactsListShown = !isContactsListShown"
          )
            p.font-plus.text-gray-300.text-xs CONTATOS
            .material-icons.notranslate {{ isContactsListShown ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
          .flex.flex-col.w-full.overflow-y-scroll.justify-start.gap-2.max-h-36(
            :class="{'!max-h-60' : !isMatesListShown}"
            v-if="isContactsListShown"
          )
            dialer-contact-card(
              v-for="contact in contacts",
              :key="contact.contact.id",
              :name="contact.contact.name",
              :endpoint="contact.number"
              @make-call="handleMakeCall(contact.number)"
            )
              template(#avatar)
                avatar(
                  :src="`https://${contactListBaseUrl}/uploads/${contact.contact.id}.jpg`",
                  :avatarId="`${contact.contact.id}`",
                )

            p.text-sm.text-gray-600(v-if="!loadingContacts && !contacts.length") Nenhum contato encontrado
            observer(v-if="!loadingContacts", @intersect="loadMoreContactsPhones")
            loading-ring(v-if="loadingContacts")
        .flex.flex-col.w-full

          .flex.w-full.justify-between.cursor-pointer(
            @click="isMatesListShown = !isMatesListShown"
          )
            p.font-plus.text-gray-300.text-xs COLEGAS
            .material-icons.notranslate {{ isMatesListShown ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
          .flex.flex-col.w-full.overflow-y-scroll.justify-start.gap-2.max-h-36(
            :class="{'!max-h-60' : !isContactsListShown}"
            v-if="isMatesListShown"
          )
            dialer-contact-card(
              v-for="mate in mates",
              v-if="mate.id != user.id"
              :key="mate.id",
              :name="mate.username",
              :endpoint="mate.endpoint"
              @make-call="handleMakeCall(mate.endpoint)"
            )
              template(#avatar)
                avatar(
                  :src="`https://${cserviceBaseUrl}/avatar/${mate.id}.png`",
                  :avatarId="`${mate.id}`",
                )

            p.text-sm.text-gray-600(v-if="!loadingMates && !mates.length") Nenhum colega encontrado
            loading-ring(v-if="loadingMates")
</template>
<script>
import DialerContactCard from '@/components/Dialer/dialer-contact-card.vue';
import DialerHeader from '@/components/Dialer/dialer-header.vue';
import DialerLayout from '@/components/Dialer/dialer-layout.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import contactList from '@/services/contact-list.js';
import Avatar from '@/components/Avatars/avatar.vue';
import Observer from '@/components/Observer.vue';
import callsMixins from '@/mixins/invite-calls';
import cservice from '@/services/cservice.js';
import { debounce } from '@/helpers';

export default {
  mixins: [callsMixins],
  components: {
    DialerContactCard,
    DialerHeader,
    DialerLayout,
    LoadingRing,
    IconInput,
    Observer,
    Avatar,
  },
  data() {
    return {
      cserviceBaseUrl: process.env.VUE_APP_CSERVICE,
      contactListBaseUrl: process.env.VUE_APP_CLIST,
      searchInput: '',
      contactsLastRequestLength: 0,
      contactsOffset: 0,
      isContactsListShown: true,
      isMatesListShown: true,
      loadingContacts: false,
      loadingCalling: false,
      loadingMates: false,
      backupMatesList: [],
      matesList: [],
      contacts: [],
    };
  },
  watch: {
    searchInput() {
      this.searchMates();
      this.searchContactPhone();
    },
  },
  computed: {
    mates() {
      return this.matesList.sort((a, b) => a.username.localeCompare(b.username));
    },
  },
  methods: {
    handleMakeCall(endpoint) {
      if (!endpoint || !this.isOnlyNumbers(endpoint)) return this.$toast.error('Não foi possível iniciar a chamada');
      if (this.loadingCalling) return;
      this.loadingCalling = true;
      try {
        this.makeCall(endpoint);
      } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possível iniciar a chamada');
        this.loadingCalling = false;
      }
    },
    isOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
    async getAllMates() {
      if (this.loadingMates) return;
      this.loadingMates = true;
      try {
        const data = await cservice.getAllUsers({ status: 'activated', withoutOpensUsers: true });
        this.matesList = data;
        this.backupMatesList = data;
      } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possível carregar os colegas');
      } finally {
        this.loadingMates = false;
      }
    },
    searchMates: debounce(function () {
      this.matesList = this.backupMatesList.filter((mate) => {
        return mate.username.toLowerCase().includes(this.searchInput.toLowerCase());
      });
    }, 500),
    searchContactPhone: debounce(async function () {
      this.contacts = [];
      this.contactsOffset = 0;
      this.contactsLastRequestLength = 0;
      await this.getContactsPhones();
    }, 500),
    async loadMoreContactsPhones() {
      if (this.contactsLastRequestLength < 20) return;
      this.contactsOffset = this.contactsOffset + 20;
      await this.getContactsPhones();
    },
    async getContactsPhones() {
      if (this.loadingContacts) return;
      this.loadingContacts = true;
      try {
        const payload = {
          companyId: this.user.company_id,
          limit: 20,
          orderBy: 'name',
          offset: this.contactsOffset,
        };
        if (this.searchInput) payload.filter = this.searchInput.toLowerCase();
        const data = await contactList.getContactsPhones(payload);
        this.contacts = [...this.contacts, ...data.phones];
        this.contactsLastRequestLength = data.phones.length;
      } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possível carregar os contatos');
      } finally {
        this.loadingContacts = false;
      }
    },
  },
  async created() {
    await this.getAllMates();
    await this.getContactsPhones();
  },
};
</script>
