import $http from './client';
const service = `${process.env.VUE_APP_CAMPAIGNS}`;
import qs from 'qs';

async function getCampaigns(payload) {
  try {
    const response = await $http.get(`${service}/campaigns`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getCampaignById(campaignId) {
  try {
    const response = await $http.get(`${service}/campaigns/${campaignId}`);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getCampaignContacts(payload, contactName) {
  try {
    const response = await $http.get(`${service}/campaign-contacts`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function createCampaign(payload) {
  try {
    const response = await $http.post(`${service}/campaigns`, payload);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function addContactsToCampaign(contactList) {
  try {
    const response = await $http.post(`${service}/campaign-contacts`, contactList);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function setCampaignStatus(campaignId, status) {
  try {
    const response = await $http.patch(`${service}/campaigns/${campaignId}`, { status });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export default {
  getCampaigns,
  getCampaignById,
  getCampaignContacts,
  createCampaign,
  addContactsToCampaign,
  setCampaignStatus,
};
