<template lang="pug">
.w-96.h-full.flex.items-start.justify-start.flex-col.overflow-y-scroll
  loading-ring(v-if="loading")
  .flex.flex-col.w-full.relative(v-else)
    .flex.gap-2.ml-auto.justify-start(class="w-[95%]")
      p Ver campanhas finalizadas
      switch-checkbox.mt-2(
        v-model="campaignFilter"
      )
    .w-full.flex.flex-col.gap-2.items-end
      campaign-card(
        v-for="campaign in campaigns"
        :name="campaign.name"
        :createdBy="campaign.createdBy"
        :campaignId="campaign.id"
        :status="campaign.status"
        :key='campaign.id'
      )
      p.text-brand-400.font-semibold.cursor-pointer.text-center.w-full.mb-4(
        class="transition-all active:scale-90"
        v-if="pagesToLoad > 0"
        @click="loadMoreCampaigns"
      ) Carregar mais
</template>

<script>
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import SwitchCheckbox from '@/components/Inputs/SwitchCheckbox.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import CampaignCard from '@/components/Cards/CampaignCard.vue';
import vDropdown from '@/components/Dropdown/v-dropdown.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import Campaign from '@/services/campaigns';
import { Tooltip } from 'floating-vue';

export default {
  components: {
    FTooltip: Tooltip,
    vButtonSecondary,
    SwitchCheckbox,
    CampaignCard,
    DropdownItem,
    LoadingRing,
    vDropdown,
  },
  data() {
    return {
      campaigns: [],
      pagesToLoad: 0,
      campaignFilter: false,
      loading: false,
    };
  },
  computed: {
    handleUpdateCampaigns() {
      return this.$store.getters['campaign/getHandleCampaignUpdate'];
    },
  },
  watch: {
    async handleUpdateCampaigns() {
      this.loading = true;
      await this.getCampaigns();
      this.loading = false;
    },
    async campaignFilter(val) {
      this.loading = true;
      await this.getCampaigns();
      this.loading = false;
    },
  },
  methods: {
    async getCampaigns() {
      const { getCampaigns } = Campaign;
      this.pagesToLoad = 0;
      try {
        const filter = this.campaignFilter ? null : 'ongoing';
        if (filter) this.$store.commit('campaign/setActiveCampaignId', null);
        const data = await getCampaigns({
          $skip: this.pagesToLoad * 10,
          ...(filter && { status: filter }),
          $or: [
            {
              createdBy: this.user.id,
            },
            {
              assignedTo: this.user.id,
            },
          ],
          $sort: { createdAt: -1 },
        });
        this.campaigns = data.data;

        if (filter && this.campaigns?.length) this.$store.commit('campaign/setActiveCampaignId', this.campaigns[0].id);

        if (data.total <= 10) return (this.pagesToLoad = 0);
        let totalCampaigns = data.total;
        while (totalCampaigns > 10) {
          this.pagesToLoad++;
          totalCampaigns = totalCampaigns - 10;
        }
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async loadMoreCampaigns() {
      const { getCampaigns } = Campaign;
      this.loading = true;
      try {
        const filter = this.campaignFilter ? null : 'ongoing';
        const data = await getCampaigns({
          $skip: this.pagesToLoad * 10,
          $or: [
            {
              createdBy: this.user.id,
            },
            {
              assignedTo: this.user.id,
            },
          ],
          ...(filter && { status: filter }),
          $sort: { createdAt: -1 },
        });
        data.data.forEach((campaign) => {
          this.campaigns.push(campaign);
        });
      } catch (e) {
        this.$toast.error(e);
      }
      this.pagesToLoad--;
      this.loading = false;
    },
    statusLabel(status) {
      switch (status) {
        case 'ongoing':
          return 'Em progresso';
        case 'finished':
          return 'Finalizadas';
      }
    },
  },
  async created() {
    this.loading = true;
    await this.getCampaigns();
    this.loading = false;
  },
};
</script>
