import ContactMixin from '@/mixins/contact-list';
import OperatorMixin from '@/mixins/user-list';

export default {
  mixins: [OperatorMixin, ContactMixin],
  computed: {
    identityContact() {
      return this.activeContactId ? this.contact : this.operator;
    },
  },

  methods: {
    /**
     * @param {string} name
     */
    getInitials(name) {
      const [firstname, lastname] = name.split(' ');

      if (!firstname) return '??';
      if (!firstname.length) return '??';
      if (!lastname) return firstname[0];
      if (!lastname.length) return firstname[0];
      return firstname[0] + lastname[0];
    },
    setContactOrOperator({ id, number }) {
      this.activeContactId = null;
      this.activeEndpoint = null;
      if (number?.length < 8) this.activeEndpoint = number;
      else this.activeContactId = id;
    },
  },
};
