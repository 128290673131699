<template lang="pug">
.flex.rounded-xl.border.items-center.justify-center.bg-gray-100.gap-2.h-14.px-1.relative(
  v-if="isValidEvent && (currentDialState === dialState.IN_CALL || currentDialState === dialState.CALLING)"
)
  f-tooltip
    .flex.relative.cursor-help
      .h-8.w-8.rounded-full.bg-slate-800.flex.items-center.justify-center.overflow-hidden(class="dark:invert")
        avatar(
          v-if="!identityContact.unknown"
          :src="identityContact.avatarUrl",
          :avatarId="identityContact.id",
        )
        img.h-full(
          v-else
          src="@/assets/images/default_pfp.png"
        )
      .material-icons.notranslate.absolute.-bottom-1.right-0.text-gray-600(
        class="!text-xs"
      ) info
    template(#popper)
      p.font-plus.text-white.font-bold Contato
      p.font-plus.text-white {{ identityContact?.name }}
      p.font-plus.text-white {{ callerNumber }}
  .flex.bg-white.border.rounded-xl.p-1.items-center.justify-center.gap-1(class="dark:invert")
    loading-ring(v-if="loadingHangUp")
    .flex.cursor-pointer.transition-all.rounded-md.items-center.justify-center.w-8.h-8(
      class="hover:bg-gray-100",
      v-tooltip="'Ativar/Desativar microfone'"
      :selected="muted"
      :class="{'opacity-60 pointer-events-none' : isTransferring}"
      @click="checkMute"
      v-if="isWebRTC && !loadingHangUp && currentDialState !== dialState.CALLING"
    )
      .material-icons.notranslate.text-xl.text-gray-600 {{ muted ? 'mic_off' : 'mic' }}
    .flex.cursor-pointer.transition-all.rounded-md.items-center.justify-center.w-8.h-8(
      class="hover:bg-gray-100",
      v-tooltip="'Desligar chamada'"
      @click="hangUp"
      v-if="isWebRTC && !loadingHangUp"
    )
      .material-icons.notranslate.text-xl.text-red-500 call_end
    f-dropdown(
      placement='bottom-end'
      :shown="isDialerOpen",
      @show="handleShow",
      @hide="handleHide"
      v-if="!loadingHangUp"
    )
      .flex.cursor-pointer.transition-all.rounded-md.items-center.justify-center.w-8.h-8(
        class="hover:bg-gray-100",
        :class="{'bg-gray-100' : isDialerOpen}"
        v-tooltip="'Abrir chamada'"
      )
        .material-icons.notranslate.text-xl.text-gray-600 more_horiz
      template(#popper)
        calling(
          v-if="isValidEvent && currentDialState === dialState.CALLING",
          :data="socketMessage",
          :extra="extraCall",
          @hangup-call="currentDialState = dialState.DEFAULT"
        )
        in-call(
          v-if="isValidEvent && currentDialState === dialState.IN_CALL",
          :data="socketMessage",
          :extra="extraCall",
          @hangup-call="currentDialState = dialState.DEFAULT"
        )
  .absolute.w-fit.h-fit.z-10.-top-1.-right-0.rounded-full.items-center.justify-center.bg-brand.px-1(
    v-if="isUpdating",
    class="dark:invert"
  )
    .material-icons.notranslate.text-xs.text-white.z-20 autorenew
  .flex(v-else)
    .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full(
      :class="statusBallColor",
      class="dark:invert"
    )
    .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full.animate-ping.cursor-pointer(
      v-if="!isRegistered",
      v-tooltip="'Dispositivo desconectado, clique para tentar reconectar'",
      :class="statusBallColor",
      class="dark:invert",
      @click="synchronize"
    )
f-dropdown(
  v-else
  placement='bottom-end'
  :shown="isDialerOpen",
  @show="handleShow",
  @hide="handleHide"
)
  .flex.relative
    .flex.rounded-xl.border.items-center.justify-center.w-11.h-11.transition-all.cursor-pointer(
      class="hover:bg-gray-100 active:scale-90",
      :class="{'bg-red-100' : isValidEvent && currentDialState === dialState.INCOMING_CALL}"
    )
      .material-icons.text-gray-500(style="font-size: 18px") phone
    .flex.rounded-xl.border.items-center.justify-center.w-11.h-11.transition-all.cursor-pointer.absolute.animate-ping.bg-red-100(v-if="isValidEvent && currentDialState === dialState.INCOMING_CALL")
    .absolute.w-fit.h-fit.z-10.-top-1.-right-0.rounded-full.items-center.justify-center.bg-brand.px-1(
      v-if="isUpdating",
      class="dark:invert"
    )
      .material-icons.notranslate.text-xs.text-white.z-20 autorenew
    .flex(v-else)
      .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full(
        :class="statusBallColor",
        class="dark:invert"
      )
      .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full.animate-ping.cursor-pointer(
        v-if="!isRegistered",
        v-tooltip="'Dispositivo desconectado, clique para tentar reconectar'",
        :class="statusBallColor",
        class="dark:invert",
        @click="synchronize"
      )
  template(#popper)
    incoming-call(
      v-if="isValidEvent && currentDialState === dialState.INCOMING_CALL",
      :data="socketMessage",
      :extra="extraCall",
      @hangup-call="currentDialState = dialState.DEFAULT"
    )
    dialer-home(v-else)
</template>
<script>
import useGetDeviceRegistryMixin from '@/mixins/cstate/use-get-device-registry';
import IncomingCall from '@/components/Dialer/dialer-incoming-call.vue';
import DialerLayout from '@/components/Dialer/dialer-layout.vue';
import DialerOption from '@/components/Dialer/dialer-option.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import DialerHome from '@/components/Dialer/dialer-home.vue';
import InCall from '@/components/Dialer/dialer-in-call.vue';
import Calling from '@/components/Dialer/dialer-calling';
import LoadingRing from '@/components/LoadingRing.vue';
import Avatar from '@/components/Avatars/avatar.vue';
import { Dropdown, Tooltip } from 'floating-vue';
import SipMixin from '@/mixins/invite-calls.js';

export default {
  mixins: [useGetDeviceRegistryMixin, UserOrContactMixin, SipMixin],
  components: {
    FDropdown: Dropdown,
    FTooltip: Tooltip,
    IncomingCall,
    DialerLayout,
    DialerOption,
    LoadingRing,
    DialerHome,
    Calling,
    Avatar,
    InCall,
  },
  data() {
    return {
      mute: 'off',
      loadingHangUp: false,
      isUpdating: false,
      dialState: {
        DEFAULT: 0,
        INCOMING_CALL: 1,
        CALLING: 2,
        IN_CALL: 4,
        CALL_ENDED: 5,
      },
    };
  },
  watch: {
    currentDialState(val) {
      this.$store.commit('sip/setTransferring', false);
      this.$store.commit('sip/setMuted', false);
      this.$store.commit('sip/setCallStartedAt', null);
      this.$store.commit('sip/setTransferStartedAt', null);
      if (val === this.dialState.DEFAULT) return this.handleHide();
      this.handleShow();
    },
    async callerNumber(val) {
      this.setContactOrOperator({
        id: this.callerId,
        number: val,
      });
    },
  },
  computed: {
    isRegistered() {
      return this.$store.getters['cstate/IS_DEVICE_CONNECTED'];
    },
    statusBallColor() {
      if (
        this.currentDialState == this.dialState.INCOMING_CALL ||
        this.currentDialState == this.dialState.CALLING ||
        this.currentDialState == this.dialState.IN_CALL ||
        !this.isRegistered
      )
        return 'bg-rose-500';
      return 'bg-emerald-400';
    },
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    fromId() {
      return this.call?.fromId || this.call?.from_id;
    },
    isMineCall() {
      return this.fromId === this.user.id || this.call?.from === this.user.endpoint;
    },
    callerId() {
      return this.isMineCall ? this.call?.toId : this.fromId;
    },
    callerNumber() {
      return this.isMineCall ? this.call?.to : this.call?.from;
    },
    isDialerOpen() {
      return this.$store.getters['dialer/getIsDialerOpen'];
    },
    currentDialState: {
      set(newstate) {
        this.$store.commit('cstate/SET_DIALER_STATE', newstate);
      },
      get() {
        return this.$store.getters['cstate/GET_DIALER_STATE'];
      },
    },
    webphone() {
      return this.$store.getters['sip/agent'];
    },
    session() {
      return this.$store.getters['sip/session'];
    },
    activeInterface() {
      if (!this.webphone) return true;
      return !!this.session.session;
    },
    extraCall() {
      return this.$store.getters['cstate/GET_EXTRA_CALL'];
    },
    socketMessage() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    isValidEvent() {
      if (this.isWebRTC) {
        return this.session.session && this.activeInterface;
      } else return true;
    },
    session() {
      return this.$store.getters['sip/session'];
    },
    muted() {
      return this.$store.getters['sip/muted'];
    },
    isTransferring() {
      return this.$store.getters['sip/transferring'];
    },
  },
  methods: {
    async resetWebphone() {
      if (!this.isWebRTC) return;
      this.$store.commit('sip/destroyUserAgent');
      this.$store.dispatch('sip/createUserAgent', {
        username: this.user.username,
        endpoint: this.user.endpoint,
      });
    },
    async synchronize() {
      if (this.isUpdating) return;
      this.isUpdating = true;
      try {
        await this.resetWebphone();
        await this.getDeviceRegistry(this.user.endpoint);
      } catch (error) {
        console.error(error);
        this.$toast.error('Falha ao buscar registro do ramal...');
      } finally {
        this.isUpdating = false;
      }
    },
    async hangUp() {
      if (this.loadingHangUp) return;
      this.loadingHangUp = true;
      try {
        await this._hangUpCall();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingHangUp = false;
      }
    },
    handleShow() {
      this.$store.commit('dialer/setIsDialerOpen', true);
    },
    handleHide() {
      this.$store.commit('dialer/setIsDialerOpen', false);
    },
    async checkMute() {
      if (this.mute == 'off') {
        this.mute = 'on';
        this.$store.commit('sip/setMuted', true);
      } else {
        this.mute = 'off';
        this.$store.commit('sip/setMuted', false);
      }
      try {
        if (this.webphone && this.session) return this.muteSession(this.muted);
        await this.muteFunction(this.mute);
      } catch (error) {
        console.error(error);
        if (this.muted) return this.$toast.error('Não foi possível ativar o microfone');
        this.$toast.error('Não foi possível desativar o microfone');
      }
    },
  },
};
</script>
