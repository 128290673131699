<template lang="pug">
f-tooltip.w-fit(
  :disabled="!$slots.tooltip",
)
  .flex.cursor-pointer.transition-all.rounded-md.items-center.justify-center.w-9.h-9(
    @click="$emit('click')"
    class="hover:opacity-60 active:scale-90",
    :class="[backgroundColor]"
  )
    .material-icons.notranslate.text-lg(
      :class="[iconColor]"
    ) {{ icon }}
  template(#popper)
    slot(name="tooltip")
</template>

<script>
import { Tooltip } from 'floating-vue';

export default {
  components: {
    FTooltip: Tooltip,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
    lightGreen: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundColor() {
      if (this.green) return 'bg-green-400';
      if (this.lightGreen) return 'bg-green-400 bg-opacity-20';
      if (this.red) return 'bg-red-500';
      if (this.selected) return 'bg-white';
      return 'bg-gray-800';
    },

    iconColor() {
      if (this.green) return 'text-white';
      if (this.lightGreen) return 'text-green-400';
      if (this.selected) return 'text-gray-800';
      return 'text-white';
    },
  },
};
</script>
