import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';
import colegas from './colegas';
import cstate from './cstate';
import rh from './rh';
import performance from './performance';
import modal from './modal';
import sip from './sip';
import overlay from './overlay';
import chat from './chat';
import email from './email';
import cases from './cases';
import license from './license';
import userPreferences from './user-preferences';
import newsletter from './newsletter';
import chatView from './chat-view';
import campaign from './campaign';
import attendanceState from './attendance-state';
import contactList from './contact-list';
import createCache from 'vuex-cache';
import usersList from './users-list';
import callTagsState from './call-tags-state';
import providersActions from './providers-actions';
import dialer from './dialer';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentPageTitle: 'Opens',
    collapseDialer: false,
    isPaused: false,
    token: null,
    modalName: null,
    itemId: null,
    actualDevice: null,
    pause: {},
    abandonid: null,
    companyPauses: null,
    config: {},
    movidesk: {},
    movideskContext: {},
  },
  plugins: [createCache()],
  getters: {
    collapseDialer: function (state) {
      return state.collapseDialer;
    },
    config: function (state) {
      return state.config;
    },
    GET_COMPANY_PAUSES: function (state) {
      return state.companyPauses;
    },
    GET_PAUSE: function (state) {
      return state.pause;
    },
    GET_ADEVICE: function (state) {
      return state.actualDevice;
    },
    GET_MODAL: function (state) {
      return state.modalName;
    },
    GET_TOKEN: function (state) {
      return state.token;
    },
    GET_ITEMID: function (state) {
      return state.itemId;
    },
    GET_ABANDONID: function (state) {
      return state.abandonid;
    },
    IS_PAUSED: function (state) {
      return state.isPaused;
    },
    GET_MOVIDESK: function (state) {
      return state.movidesk;
    },
    GET_MOVIDESK_CONTEXT: function (state) {
      return state.movideskContext;
    },
    GET_CURRENT_PAGE_TITLE: function (state) {
      return state.currentPageTitle;
    },
  },
  mutations: {
    SET_COMPANY_PAUSES: function (state, pauses) {
      state.companyPauses = pauses;
    },
    SET_PAUSE: function (state, pause) {
      state.pause = pause;
    },
    SET_ADEVICE: function (state, device) {
      state.actualDevice = device;
    },
    SET_MODAL: function (state, payload) {
      if (!payload) {
        state.modalName = null;
        return;
      }
      if (typeof payload === 'string' || payload instanceof String) {
        state.modalName = payload;
        return;
      }
      state.modalName = payload.name;
    },
    SET_ITEMID: function (state, name) {
      state.itemId = name;
    },
    SET_TOKEN: function (state, token) {
      state.token = token;
    },
    SET_ABANDONID: function (state, abandonid) {
      state.abandonid = abandonid;
    },
    SET_IS_PAUSED: function (state, paused) {
      state.isPaused = paused;
    },
    SET_CONFIG: function (state, config) {
      state.config = config;
    },
    SET_MOVIDESK: function (state, movideskIntegration) {
      state.movidesk = movideskIntegration;
    },
    SET_MOVIDESK_CONTEXT: function (state, context) {
      state.movideskContext = context;
    },
    SET_COLLAPSE_DIALER: function (state, collapse) {
      state.collapseDialer = collapse;
    },
    SET_CURRENT_PAGE_TITLE: function (state, title) {
      state.currentPageTitle = title;
    },
  },
  modules: {
    user,
    modal,
    cstate,
    colegas,
    rh,
    performance,
    sip,
    overlay,
    chat,
    email,
    cases,
    license,
    userPreferences,
    contactList,
    newsletter,
    chatView,
    campaign,
    attendanceState,
    usersList,
    callTagsState,
    providersActions,
    dialer,
  },
});
