<template lang="pug">
.rounded-md.bg-zinc-50.flex.justify-between.items-center.px-4.relative.shadow-md.cursor-pointer.h-20.opacity-40(
  class='w-[95%] hover:opacity-100 active:scale-90 transition-all'
  @click="selectCampaign"
  :class="{'opacity-100 border-r-2 border-brand' : activeCampaignId == campaignId}"
)
  .flex.flex-col.gap-1.items-start
    .flex.gap-2
      p.font-semibold {{ name }}
    p.text-xs.text-zinc-500 Criada por: <span class='font-semibold'>{{ createdByUser?.username || 'Não identificado'}}</span>
  .flex.gap-2.items-start
    p.bg-red-200.p-2.rounded-md.text-red-500.text-xs(v-if="status == 'finished'") Finalizada
    p.bg-blue-200.p-2.rounded-md.text-blue-500.text-xs(v-else) Em andamento

</template>

<script>
import { Tooltip } from 'floating-vue';
import cservice from '@/services/cservice';

export default {
  props: ['name', 'createdBy', 'campaignId', 'status'],
  components: {
    FTooltip: Tooltip,
  },
  data() {
    return {
      createdByUser: {},
    };
  },
  computed: {
    activeCampaignId() {
      return this.$store.getters['campaign/getActiveCampaignId'];
    },
  },
  methods: {
    selectCampaign() {
      if (!this.activeCampaignId || this.activeCampaignId != this.campaignId)
        return this.$store.commit('campaign/setActiveCampaignId', this.campaignId);
      return this.$store.commit('campaign/setActiveCampaignId', null);
    },
    async getCreatedByInfo() {
      const { getUserById } = cservice;
      try {
        const data = await getUserById(this.createdBy);
        this.createdByUser = data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    await this.getCreatedByInfo();
  },
};
</script>
